﻿.site-footer {
	background-color: #313E48;
	color: #fff;
	font-weight: bold;
	width: 100%;
	font-size: 1.5rem;
	text-align: center;

	.phone {
		padding-left: 5rem;
		color: #fff;
		margin: 2rem 0 2rem -3.2rem;
		text-decoration: none;

		&:before {
			display: inline-block;
			content: " ";
			width: 2.2rem;
			height: 2.4rem;
			margin-right: 1rem;
			background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAAbCAYAAACa9mScAAAABHNCSVQICAgIfAhkiAAAAORJREFUOE/tleENAUEQhb/XARWgAkrQASqgA3RABZRABXQgOlCCEnTwZC53OC5ixR/JTXK5vezsy87L3Dey3QN2QJv0OAMj2Y7FRtLiUcN2Ixe+AC1Jx4r9GTAIEQNNSZF8C9sTIJ5T/m5X5PSBQyFSukVCVWHBpBBZJhx8TA2RcSYiSd+I2L6XU4tkFtaevHZS7cnfeAL8BEolfiawJTjcfQsl22tgCiyfQf7xD5hDfA6sqsBV6ligIylGRyls72MkAFtJQf7n/cDjPsrZRF3xkeBFkToEjhmgbccQCpNS4yJpfQU02+A3uYImUwAAAABJRU5ErkJggg==');
			background-position: center;
			background-size: contain;
			background-repeat: no-repeat;
			vertical-align: middle;
		}

		@include media(">=desktop") {
			color: $orange;
			padding-left: 4rem;
			margin: 0 0 0 -1rem;

			&:hover {

				&:before {
					background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAWCAYAAAAfD8YZAAAABHNCSVQICAgIfAhkiAAAAO9JREFUOE/tlDFuAjEQRefb9KxvQMENaCPBEZIe0OYGoGy4BBvBDbICRE9HCTfITbxIlNg/shDWJorQbhkJN7Y082bG4/GH/RilIBYikki9VRKcmrd1ATsfldLSHTMtyhtr8+EzPS9a6553l52Zbb+ibZEmcN4m2Qoo8zG14qCa1DnXFdU60/snJbJXGucfdo9DhEke61V89QLQj3A4NIFDtQ+4QcceDfufE9bgiaPr3Y9h83EBYTvJ1i+/g98dEjsfDgD1GSDSv5r3zaEaIMJ/yVBwDJnDbrJVWgVtVYaCAIqXJYB2nbuTPImSSRDAbxk12xc4D06vAAAAAElFTkSuQmCC');
				}
			}

			&:before {
				background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAWCAYAAAAfD8YZAAAABHNCSVQICAgIfAhkiAAAAO9JREFUOE/tlDFuAjEQRefb9KxvQMENaCPBEZIe0OYGoGy4BBvBDbICRE9HCTfITbxIlNg/shDWJorQbhkJN7Y082bG4/GH/RilIBYikki9VRKcmrd1ATsfldLSHTMtyhtr8+EzPS9a6553l52Zbb+ibZEmcN4m2Qoo8zG14qCa1DnXFdU60/snJbJXGucfdo9DhEke61V89QLQj3A4NIFDtQ+4QcceDfufE9bgiaPr3Y9h83EBYTvJ1i+/g98dEjsfDgD1GSDSv5r3zaEaIMJ/yVBwDJnDbrJVWgVtVYaCAIqXJYB2nbuTPImSSRDAbxk12xc4D06vAAAAAElFTkSuQmCC');
			}
		}
	}

	.footer-section--icons { //<-garbage class name, BTW
		a {
			padding-left: 2rem;
			color: blue;
			margin: 2rem 0;
			text-decoration: none;

			&:before {
				display: inline-block;
				content: " ";
				width: 3rem;
				height: 3rem;
				margin-right: 1rem;
				background-position: center;
				background-size: contain;
				background-repeat: no-repeat;
				vertical-align: middle;
			}

			&.youtube:hover {
                &:before {
                    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAAAiCAYAAAA6RwvCAAAA9UlEQVRYhc2YaxLCIAyEoeM5PZGH9QY4OoOjNO8slPwVNp8L2Vrr83EvjmqOtdUjfDOu8wBQe1QoDSQCIOmwQMcCCJMmBzIDQtSmQGZCsD1GkBUQZK+D+2BRNQrk0urj63VDywWP3ntt1XLElY6GfeL4XnE3TiXdkagbUrFOZS5rQ7qJmBoIEHJ8UzDoHAm7s12goSo8aSiQ9KgjjgaSNxlHIgBixLufCxOqakeDhBG1LEcjCYxuhsE7SAW8u0Tr82W2CbRfkBmPfa2+PUdHVsL89aKOZgXMqQd3R2bCkNq7/FRUc6RvzI5p+m8JSsgKZXe0lPICk3IoLpcAYhkAAAAASUVORK5CYII=');
                }
            }

            &.youtube:before {
                background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAABHNCSVQICAgIfAhkiAAAAkNJREFUSEu9Vz1uE0EU/r4TBFeUgROATwA+QYxEn/gEREoFTRyJho7UFDgSPY44APEJcJEWkYID4AaJFLzoW2as2dmZ/fOSJ61Wlt+bb973fpdoKWY2BfAMwFMAD9xb1msAv9z7gqR+NwrrNMxMAK8AHDuwxgMB3ACYk7yoU84COw8/dgCMcXSBGcmr1AWSwGa2AHDYxr0WOgLXeSWpAJvZEsBBiwO7qCxIzkKDEvDAnsYXK3m+BXYx/dzFjR66Y5/1BbDL3h87JFLbO1yRnEjZA88BnEbWlwDeF0qZzMyhmdlz54TOfRLpFZR7YDWAvUBhRVLGO4ljUg1lPzhoTXLMTGxfkFR27yxmlmLzsYBFp7pTKJOu9NbQrlYbJ+1MwOos6sGhjEiK/oqYmWh7C+A1yZ9NlLh4f430zgWsGJQSgGRdK30EQBXwG8A7PST/NCRaDLwSsMVGLYG92XcAJyRVBSmGlKT/BfgawBuSX7oC96Va00e1/4nk3z5Ud02uhwBeAvhA8rZncp3dRzkdAdBcD6UoJ60y3yp/JGZok3eZGKf6xMi3TMWr0tb6AIU2meFzSXLqgVN06DLaHOLVZRMvdK5JhGXpl0Gdq7oPpeiK4TyOvd7V4ZT9dviEwKlCHxJ8o5WYpBz8N4+9mFmK8qHAS4MntewNuWH6S1c2zdx6O5TnoneaGrF1U0gx1xCPR2Zb6lcAjnxMY6PaTxgpu7jrEybenXIXKHa1pkWiEThIPNWjWFCn0xOKal3ZuswtEPEt7wCyiwcQTA/OGQAAAABJRU5ErkJggg==');
            }
            
            &.instagram:hover {
                &:before {
                    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABsAAAAbCAYAAACN1PRVAAADZUlEQVRIia2W7UtTURjA7727dy/OZSbT0nwpNRINZy9YOtAhgiFjBorfFpSk+KVJQR/6Vv/A+iIFZVAQiFKTgkALLfyguDDJWdhcTTbU1dh8a2/3JZ6rZ9zp3K66B8Y523nO+e05z9vBfc3FmFBkeqOJ9biNrHflFLvkzOA213FMpEhKKvy4TBEgsnMniOy8nmB/r1O4MwqTt3cXRr58/srYZ4+KPTyR4EoVR9U09YVHBjpiYAAKvXv5C1lBZOXQREGpE1eqXPuFsEuLlcyCLfqHpY1tzxCQh0mKy31IQaozWMKjQ9cOY5WstVMbmRgZZl0OBbblmp7Q2xdmAnyUCpCs5Ua7VGd4DR/+rNqmMjwtnYM54/h+j7ds48Gt6cjkRw1cHetdoQ5qDZGVE2G9KyTMyfJLy7Rt6gSlvToWGX9ft62CE+zf5SJeuaDUmfC0RFa1dmoRCIS2TR3nz8xUW6I6eqOJgPDGtqJn38GAJDT4ZBxuBn0Hy2AEPwn1SO7fhug8gqjl1lc7OTqsxtNUH0KWvn60RuladJx3+Q5GSr3EMfVD2jYVu5mmc8h4h8YTqrphOtjfqxEsdUhKKh6Tmlo9WAYfDMPG99rPrvuuiIIBiA+ik6cD1LnLjzCSXGE97uvwGxYMDMvbu8t2Vot4khQGVwcWAYh1OdJCLgdaMkt1hjfh0aEWiccNvkmaMkQyBW7N3wUjWXbh1c41yEnIJWbRXp/sHHEwOqKGEZcrZuOt4wolIwYkCoYrVSMwgo92rkHVgPzCj2R6UwKD8JYUl/shGFApQiDa+uk5zCWFZ+6LgYmKRrJKq+dCgWHeR0oVC1cXsvTxe6GqC/Pt0DDIIQhv1uM2o2Ag80u8YJFYEK5It4tO6u08iglvenrPHN4NkytmCfCH6B37FCjQwh0ELk8LYtsdNuW0zfXm6FymsBLwOIE5NFCIsFSy6PkZviBA9eE7A5GdZ0IdFUIZylMqQGSV1o5eAKj6SO6GF1al9YZ8xjF3nlvzUczvH7ep6oZ6qrImg5mfmdwPAHxEFp3twjhukJmz5vKA4nI/bR2rxYRPOWlj29PwyMDNVFiFBEDUxToN6ggxj1TwGfPzmxm19YMK+AiuTvhm3AWLuRK90YTmrO9PSyIwJGy0UMsU1u1GGisYhv0HtXSFK1S92B8AAAAASUVORK5CYII=');
                }
            }

            &.instagram:before {
                background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABsAAAAbCAYAAACN1PRVAAAABHNCSVQICAgIfAhkiAAAAjVJREFUSEutlo1xE0EMhd+rADoIVEBSQUgFmAogFRAqACogqQCogKQCnApIKiCpIHQg5rOly97N+eeOaMbj8/pWb6UnPa01sIh4LemNpMP8PB++s+X3UtJfSXz/sM1zZ66niMDpT0mAPYUB9NH293K2AkugP5Iqivs83d0MVA573Ow7LcACI+x6gdOczwDptkTEC0mXkl7l4ontpZOjX7k4Gygi4LgOfJXckZlnkq5sLwAjig+S7m1zolkWETg+yM0r5xHxWdIn1oxFRKWQ6nk/BynTBuePlbf2DX+VtRMWqBpC/WKbk8yysciy+KLjLSLqx06wrFp4oWpvbd80rQMFi+Tqsnqs8f+WyPYCa7htI4cCSntji/T87wPWAN1KItWknigoLKKjtHtq0UT8GMwusEzdQ6aN8m776UzS11SK0d6cFFlEEAEy1inBAJCIbmyPytxUMNrhW6YKjnqWVXj3VGCk7rekC9ukrU1j/bexRydFlr1SjX9m+yLXACJivo/aNhgcaP8CScetsMIRn5K2US5nVWOziUaGPwoGo7fON0U0Ana6d1PP0rD1rOzJVfGxU66mAg4EeiXEDDnuHNebyncqSJPC6lGWXgJWKrBe2KJzU0Gb8YVoHwIG8TVRt+rcFLCI4KLzLvesKrbuIKUS/EdZo3NL29cTAWgH7h1kq+Sro6e9yrWAUzC2vcthFzUROrBGFRghFMz/GKOIHuzujDjrgQ1kplXxXRdXOK8BiiiPDtN/9UBczrsCa+AAAAAASUVORK5CYII=');
            }

            &.linkedin:hover {
                color: blue;

                &:before {
                    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAYAAACpSkzOAAADFUlEQVRIib1Wy08TYRCf+b5vu9taRaAFWxsNNSCPQIgaBfUiUTwYEk2Mjx4kXiRemhgPevGkVw8QjYkevDXG6B9gQoInPRgVfJCAoFFelkcoz27b3W9MF4pdiJLg4u+0M7P7/WZn5psZnLtxccjo6w6BYQAAgbNAQEUBvrd+CBNnaojSusMEdqDmIUZGZpUWARgHQOYYERkZFDYN4yAq6+MsWPaC5hM1RverfaQvohNkNrdFRe2kqGtoTHc+a+Xh6sNK44nnIBQneOxELLD7pf7k/rfssx7rSGNRyT30eKXjRDQ7XadFot4VRXKxCVK6I8nC6ZYKAmkuCS4NxKGmLuYPtlNy4YjxpuuanPwp1j1lPQjFTmRVXFapeSRkUkipFALJJX1+FRIt3zkEdKkAnBNICWSkEUxz2Z4HoYDNW1boN3i46ktOlrOJUrO/pwhVN/Hy2mFUtXmLZ37WZw4N+FgoPMVKQt3oUuNA0k1zMxXm8GC5HB9RLcJ8Llsc/YEZHq4+BkKxXMKJsdtmf88V9HhNvqf6Jnq2dlpEM1NtbMeuA6wkeAuEMgAAaSvfvkwhKw1FjA+v75g/Bjx/JkIm9acP4jnZdfJ8ciWkXCT0WMd4VtTOXX2IBcV39VjH4qrsxLVItJ2X1x00h75esMKei9ZGcrvsjEc9fblVbbl0XT3bdlSLRK2z9FiHxO3Fj9G7zZaoDVWUFolqcuz7I6P37SlK6ZzvLIuL6v3HAaDXCgAXH7GgSKe5hPuf/ggy6Spz8HOzHB9RKDHJzMFPAdIXm3+7r0yj6k7lf7IhIjKNIM3PqrkyplQKKJMO5b1iABe2jrLRWy9I5p9D2bvz1zQ4NwvWwWYRMSCJdsVmYVUb+m+hsyWQSDItEi3JtrmsLCfG3MsGACkLtEjUZ4kLc9usjpoPKd05O5hGtiJtdvuY8HiJ+QLJFeLkgiInRhVQXMD8QR2FYjlARoZbjTO3byAC8wUy6N6ysoDIiVE3JReWyNaMic2CUIChqjm9zK1Bdt0Soq7hvdHXUw9p3ZFtZw1cGonK+ne/AJPDQGjy1iJ4AAAAAElFTkSuQmCC');
                }
            }

            &.linkedin:before {
                background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAYAAACpSkzOAAAABHNCSVQICAgIfAhkiAAAAi9JREFUSEvdlsuLjmEYxn+X87kmpYiSQsxCpmZhQ2QhjXKqwcpGhPwdFsaGrYXNNMKGMk4Lpch/QEQshcHkMONw6/q633q/eedrNO9r46mv7/C8z/277+e+nuv5FBFXgT3AbEA0OwIYB24oIr4C85uNX4k2atBPYGZpyln8zupmNJTA+ETQL+CWSwU2AMeAxQ3AKqDbwBFJIxHhKk8C54A5NWEV0BlJF4qgEbEGeAwsaxp0yVVIslKIiD7gCrCgadA34CxwHVgPDACra0K8vLJ1he4/p+RdiZVXKLFg+rz55d+/Az/yuXnArEnOYwX0BrAgiuEe7QRGgWHgY06sArYAT4CHwDtgbu7CdmBtAos4FdBd4EBm6of8+TLgBPYBz3Ll1pT/IPAe8LFwhT74vanUnlLCFdCwpN0l1fUDQ8BrYJekpymSRcCYJG9Z24gIA08AF3M7J+3RX4ES5v5154F+6WQkuWdW6zrgEbA0s5h2RW74aeAUsDB7dVzS2wTZTdy7TXVBK4CbwObszRegT9KDBDmR+8C2uiD7oIWzMgPZhPslXUuQj8SdVOz0exQR7o1BrqwVGzgsyS7iHv1/IDu/K95Rt0dTbZ1B9wC7xD/t0ZQgl7u/ZEEHSxa0t2RBG/MWXl4Sw1Ggpbr0Od/SHeX9CXhR8pQuwMY6BjzPd0/bpW2cxc1r1b0CRkprPb+k09a1G1dz31oW5CoKcnOh2yN9MOg8cKiB67pTkv7fOPgHr9FvViwOWC8AAAAASUVORK5CYII=');
            }

            &.facebook:hover {
                color: blue;

                &:before {
                    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABsAAAAbCAYAAACN1PRVAAACr0lEQVRIiWN8763MgA+wh6Tb/H/7oujfq2cW/39+5/x754oASDmTiMQfRgGRL0zC4veZJOU7f26YtxKvQQwMDDgtA1ny9/q5VX+unpYkZAjMclZLt9KfmxdNIMkyVhvPA7+PbLcnxhJ0wKyi84HV2M7gx8ppDzEchMzhCM+SZ9E2fUauRSAACubfR3deZw9ICsdr2Z8rp08QG2x4LXxyl/P3kW1LQI5HFmeBMaBBJ0GMYczK2h+YlbTWMnJwXkEW/7l5UT+M/e/NCxaQDxkYGLhgYuA4AyWGn2tmHibGIlYbz4O/j2x3wCH9H12AzTV07q/dq1MYYMH49/q51cT6CI9FWMHvYzuSYMHJAvUVccGnpLX2792rKGLghPD3jzgDJBgx9Pz/+pnx37vXtQwMDCks/9++KCbWlehxBIrnnxvmEUy5/5498Abr/1wW8fzP1dNE+QykHpnDrKz9/u/dqwLE6mX69/EtP5GKKQKg6GL69+QeJz0sY/j53YQFnzyTjNJ3VkObKrh6tATAomuewaJlDC4E/n/5aP9r/8YAfOaxMAmL//n39iVWSxnZOX/iK1iRS3o211AdfBYxMLM8ZwJVE3gVEQn+f/+igk8lyGFMTJJyF6li2fs36rjkQNEBpplEJKfjUgRN1v+RMAoAJX2YHL7sw6ygfgpsGdh7wuJ/KPIWAcAkIVfDACsbWYzsFtLKIhZDm7s/18w8ArcMVCqzaJu+oLZFjFw8/1nU9JxhfHjlyaJjagGSpKZlbM5BRcjNA7hlIEE2t7BIalkIqsfQ8yhKswCUWNi8ou1A9Ra5loAcy+4bVwirMJEBRskBjUxBUPXx59xhu//fvjCiq8EFQIkBFEfYWlZgh+BrpIJqWFDF9/fetWBonsOoYv7//M4Oykeg5A1LdQMPGBgYAPBNHp2a9HsAAAAAAElFTkSuQmCC');
                }
            }

            &.facebook:before {
                background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABsAAAAbCAYAAACN1PRVAAAABHNCSVQICAgIfAhkiAAAAdFJREFUSEu1loFNwzAQRf+fADagTEA7QekElA1gAzYAJmAE2g1gAmAC2gnoCDDBoR/sKHFsx06FpSpqYuf5/v27mBgZZjYDcAXgEsCpu2rVwf12ALYkdc0Opp46yBOA9dhL3HPBb0m+p+ZHYWb2AOC+EBJOE+ya5Hf4oAczM8n0XBFNaj+SVFH2pA1hLy4/E4PqLZOsi26ELaxSug8AG2eQLuEt2OWO5MLfa2DODF+F4TySVE4Hw8wscltyamPwsFL5PkiqBKIjAZNRziUnK6Nqd+lpZjYHcOL+p2zfrBPsDoDqqWSsunVUkedXkmvBSiUEydC9imRZskutFUy1cFG6oDvPzIphTd4SSY2yj4kMwGoMtgegnDYj7HvOHOo6GjJKLvcNTJV+lpAxa/VA0hvX6lIZWQiW070Glm3e3iC5STWwnKv3JOeKTFp//rMbmxbn21Uub+0+jnCj2tXBw8aS6904pah1ZND7/xqxRkknmRDZD4CZ/6Z1YaoXyemb6iCNE2C9XhrKIrOoFKLAStjgCzE48LhPjj52gwZbCJN069gpK3eUU/2pVbVRFsC2WhM7WfUMEqszd9rSuVFuWiZgyrUKeiN75+o1GVlJkdfO+QVvrOTguswdbQAAAABJRU5ErkJggg==');
            }

            &.twitter:hover {
                color: blue;

                &:before {
                    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAATCAYAAAB7u5a2AAACNklEQVQ4jWN8763MQArgCM+S//vg5vp/Tx9o/H1yl5NJROIPs4bhkd9HtjuCjGEPSbdh+PndhCujfgLYcBZt0+fMqroFPzfMW4nPHpDBv4/uvA4yFF2OWUb5O4j+9+4lB7tvnCJnXPFDJpDAn6unJX7tXr2c3TeuAJ/hfx/c3IDNYLDck7ucIMxiZHsQpA4kxgST/P/1M+PPzYv6Wc2dz4FciM2AP5dP6hMKwT/nDtszScp3wA1nEhb/A5P8fXKv4a+96+6w2njuB4cfFIDY/799YSRkOJtbWCQseMFhzu4Z1f5z+7IKbIqZVXQ+MPIKvGHk5n3y+8h2B0KGg8wEEYJb7zKwgBj///wWZdE2fQEKe3SVf+9cEWBgYABhFUKmgkLg39uXcD7Y8N9HtycR42WCThYQ+cKAZDg4zFmMbA9RajAIMMupHEDmgw1nllaMZ5JR+k6p4Yz8wiswDP+xctpDNmsPTVYbz4PIKYcUwKys/QE9EyLS+bcvgb+PbLf/9/YlCzmGs2gZN2L4BLlsYTG0ufPn/BHSChuIvrt/zh9BSU2gpMiELABSAAoaRi6e/8QaDApGFjU9Z6xy6AKgjAIqeECZh6DBMkrf2VyCVUBxhk0eJXxBBdffJ/eyf25ZrAwqa/AZDIpAVhN7A1wGgwDjtym17b9P7S0hNiJBQcZq7Tnv1+7VKfjUgcIcHqFQV+f8e/1M6t+TeyjFKsiVjHyCb5kl5Fb/3L6skhhHgAynHWBgYAAAaLDo0WIzB2AAAAAASUVORK5CYII=');
                }
            }

            &.twitter:before {
                background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAATCAYAAAB7u5a2AAAABHNCSVQICAgIfAhkiAAAAWtJREFUOE+llYFNAkEQRf+vQK1ArEDtQCtQK9AOxAqEDixBK5AOlA6wAqUDrGDMI7uX2wPuVpiE5HLsvNn9+2fO+mdExLGkZ0lXki4k/Uh6tT0FFREjSSPbn04vZpImthd9tRL4I0G7S3PuWYKvMjwkrSTdUXFXgYh4kfQ4cNiJpBPb4zY85wCY2qZYERHBu6MB+C+SoUKGo9tpK2mto6Q32zxnLb8rrugyy5vht5LedyQiEwX4ceTesL1mEhn+IIkCN0PJA/8vbeOWAl6jZU3duW0sWsA5Lt49NJ5sY4gCTmOg7fmB9OYyG815SA0yloT+befU1isk6cLRiu7bN667DdjYJu0eb9/vQacfOHERBTwV4HKRZ6gTM2jJrNnW0Rvwlv40VWOrHaf5Sq2+MSoKzRMUGLLQUDioL+as27bjxooRAQhv1jqEwTS2zf30RiNLRLBrLoUPQNfv7JLZMrPN7K+KrZpXZVYs+gMuLoEUqXybwgAAAABJRU5ErkJggg==');
            }
			@include media(">=desktop") {
				color: #fff;
				padding-left: 2rem;
				margin: 0 0 0 -1rem;

				&.youtube:hover {
					&:before {
						background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAAAiCAYAAAA6RwvCAAAA9UlEQVRYhc2YaxLCIAyEoeM5PZGH9QY4OoOjNO8slPwVNp8L2Vrr83EvjmqOtdUjfDOu8wBQe1QoDSQCIOmwQMcCCJMmBzIDQtSmQGZCsD1GkBUQZK+D+2BRNQrk0urj63VDywWP3ntt1XLElY6GfeL4XnE3TiXdkagbUrFOZS5rQ7qJmBoIEHJ8UzDoHAm7s12goSo8aSiQ9KgjjgaSNxlHIgBixLufCxOqakeDhBG1LEcjCYxuhsE7SAW8u0Tr82W2CbRfkBmPfa2+PUdHVsL89aKOZgXMqQd3R2bCkNq7/FRUc6RvzI5p+m8JSsgKZXe0lPICk3IoLpcAYhkAAAAASUVORK5CYII=');
					}
				}

				&.youtube:before {
					background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAABHNCSVQICAgIfAhkiAAAAkNJREFUSEu9Vz1uE0EU/r4TBFeUgROATwA+QYxEn/gEREoFTRyJho7UFDgSPY44APEJcJEWkYID4AaJFLzoW2as2dmZ/fOSJ61Wlt+bb973fpdoKWY2BfAMwFMAD9xb1msAv9z7gqR+NwrrNMxMAK8AHDuwxgMB3ACYk7yoU84COw8/dgCMcXSBGcmr1AWSwGa2AHDYxr0WOgLXeSWpAJvZEsBBiwO7qCxIzkKDEvDAnsYXK3m+BXYx/dzFjR66Y5/1BbDL3h87JFLbO1yRnEjZA88BnEbWlwDeF0qZzMyhmdlz54TOfRLpFZR7YDWAvUBhRVLGO4ljUg1lPzhoTXLMTGxfkFR27yxmlmLzsYBFp7pTKJOu9NbQrlYbJ+1MwOos6sGhjEiK/oqYmWh7C+A1yZ9NlLh4f430zgWsGJQSgGRdK30EQBXwG8A7PST/NCRaDLwSsMVGLYG92XcAJyRVBSmGlKT/BfgawBuSX7oC96Va00e1/4nk3z5Ud02uhwBeAvhA8rZncp3dRzkdAdBcD6UoJ60y3yp/JGZok3eZGKf6xMi3TMWr0tb6AIU2meFzSXLqgVN06DLaHOLVZRMvdK5JhGXpl0Gdq7oPpeiK4TyOvd7V4ZT9dviEwKlCHxJ8o5WYpBz8N4+9mFmK8qHAS4MntewNuWH6S1c2zdx6O5TnoneaGrF1U0gx1xCPR2Zb6lcAjnxMY6PaTxgpu7jrEybenXIXKHa1pkWiEThIPNWjWFCn0xOKal3ZuswtEPEt7wCyiwcQTA/OGQAAAABJRU5ErkJggg==');
                }
                
                &.instagram:hover {
					&:before {
						background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABsAAAAbCAYAAACN1PRVAAADZUlEQVRIia2W7UtTURjA7727dy/OZSbT0nwpNRINZy9YOtAhgiFjBorfFpSk+KVJQR/6Vv/A+iIFZVAQiFKTgkALLfyguDDJWdhcTTbU1dh8a2/3JZ6rZ9zp3K66B8Y523nO+e05z9vBfc3FmFBkeqOJ9biNrHflFLvkzOA213FMpEhKKvy4TBEgsnMniOy8nmB/r1O4MwqTt3cXRr58/srYZ4+KPTyR4EoVR9U09YVHBjpiYAAKvXv5C1lBZOXQREGpE1eqXPuFsEuLlcyCLfqHpY1tzxCQh0mKy31IQaozWMKjQ9cOY5WstVMbmRgZZl0OBbblmp7Q2xdmAnyUCpCs5Ua7VGd4DR/+rNqmMjwtnYM54/h+j7ds48Gt6cjkRw1cHetdoQ5qDZGVE2G9KyTMyfJLy7Rt6gSlvToWGX9ft62CE+zf5SJeuaDUmfC0RFa1dmoRCIS2TR3nz8xUW6I6eqOJgPDGtqJn38GAJDT4ZBxuBn0Hy2AEPwn1SO7fhug8gqjl1lc7OTqsxtNUH0KWvn60RuladJx3+Q5GSr3EMfVD2jYVu5mmc8h4h8YTqrphOtjfqxEsdUhKKh6Tmlo9WAYfDMPG99rPrvuuiIIBiA+ik6cD1LnLjzCSXGE97uvwGxYMDMvbu8t2Vot4khQGVwcWAYh1OdJCLgdaMkt1hjfh0aEWiccNvkmaMkQyBW7N3wUjWXbh1c41yEnIJWbRXp/sHHEwOqKGEZcrZuOt4wolIwYkCoYrVSMwgo92rkHVgPzCj2R6UwKD8JYUl/shGFApQiDa+uk5zCWFZ+6LgYmKRrJKq+dCgWHeR0oVC1cXsvTxe6GqC/Pt0DDIIQhv1uM2o2Ag80u8YJFYEK5It4tO6u08iglvenrPHN4NkytmCfCH6B37FCjQwh0ELk8LYtsdNuW0zfXm6FymsBLwOIE5NFCIsFSy6PkZviBA9eE7A5GdZ0IdFUIZylMqQGSV1o5eAKj6SO6GF1al9YZ8xjF3nlvzUczvH7ep6oZ6qrImg5mfmdwPAHxEFp3twjhukJmz5vKA4nI/bR2rxYRPOWlj29PwyMDNVFiFBEDUxToN6ggxj1TwGfPzmxm19YMK+AiuTvhm3AWLuRK90YTmrO9PSyIwJGy0UMsU1u1GGisYhv0HtXSFK1S92B8AAAAASUVORK5CYII=');
					}
				}

				&.instagram:before {
					background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABsAAAAbCAYAAACN1PRVAAAABHNCSVQICAgIfAhkiAAAAjVJREFUSEutlo1xE0EMhd+rADoIVEBSQUgFmAogFRAqACogqQCogKQCnApIKiCpIHQg5rOly97N+eeOaMbj8/pWb6UnPa01sIh4LemNpMP8PB++s+X3UtJfSXz/sM1zZ66niMDpT0mAPYUB9NH293K2AkugP5Iqivs83d0MVA573Ow7LcACI+x6gdOczwDptkTEC0mXkl7l4ontpZOjX7k4Gygi4LgOfJXckZlnkq5sLwAjig+S7m1zolkWETg+yM0r5xHxWdIn1oxFRKWQ6nk/BynTBuePlbf2DX+VtRMWqBpC/WKbk8yysciy+KLjLSLqx06wrFp4oWpvbd80rQMFi+Tqsnqs8f+WyPYCa7htI4cCSntji/T87wPWAN1KItWknigoLKKjtHtq0UT8GMwusEzdQ6aN8m776UzS11SK0d6cFFlEEAEy1inBAJCIbmyPytxUMNrhW6YKjnqWVXj3VGCk7rekC9ukrU1j/bexRydFlr1SjX9m+yLXACJivo/aNhgcaP8CScetsMIRn5K2US5nVWOziUaGPwoGo7fON0U0Ana6d1PP0rD1rOzJVfGxU66mAg4EeiXEDDnuHNebyncqSJPC6lGWXgJWKrBe2KJzU0Gb8YVoHwIG8TVRt+rcFLCI4KLzLvesKrbuIKUS/EdZo3NL29cTAWgH7h1kq+Sro6e9yrWAUzC2vcthFzUROrBGFRghFMz/GKOIHuzujDjrgQ1kplXxXRdXOK8BiiiPDtN/9UBczrsCa+AAAAAASUVORK5CYII=');
				}

				&.linkedin:hover {
					color: blue;

					&:before {
						background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAYAAACpSkzOAAADFUlEQVRIib1Wy08TYRCf+b5vu9taRaAFWxsNNSCPQIgaBfUiUTwYEk2Mjx4kXiRemhgPevGkVw8QjYkevDXG6B9gQoInPRgVfJCAoFFelkcoz27b3W9MF4pdiJLg4u+0M7P7/WZn5psZnLtxccjo6w6BYQAAgbNAQEUBvrd+CBNnaojSusMEdqDmIUZGZpUWARgHQOYYERkZFDYN4yAq6+MsWPaC5hM1RverfaQvohNkNrdFRe2kqGtoTHc+a+Xh6sNK44nnIBQneOxELLD7pf7k/rfssx7rSGNRyT30eKXjRDQ7XadFot4VRXKxCVK6I8nC6ZYKAmkuCS4NxKGmLuYPtlNy4YjxpuuanPwp1j1lPQjFTmRVXFapeSRkUkipFALJJX1+FRIt3zkEdKkAnBNICWSkEUxz2Z4HoYDNW1boN3i46ktOlrOJUrO/pwhVN/Hy2mFUtXmLZ37WZw4N+FgoPMVKQt3oUuNA0k1zMxXm8GC5HB9RLcJ8Llsc/YEZHq4+BkKxXMKJsdtmf88V9HhNvqf6Jnq2dlpEM1NtbMeuA6wkeAuEMgAAaSvfvkwhKw1FjA+v75g/Bjx/JkIm9acP4jnZdfJ8ciWkXCT0WMd4VtTOXX2IBcV39VjH4qrsxLVItJ2X1x00h75esMKei9ZGcrvsjEc9fblVbbl0XT3bdlSLRK2z9FiHxO3Fj9G7zZaoDVWUFolqcuz7I6P37SlK6ZzvLIuL6v3HAaDXCgAXH7GgSKe5hPuf/ggy6Spz8HOzHB9RKDHJzMFPAdIXm3+7r0yj6k7lf7IhIjKNIM3PqrkyplQKKJMO5b1iABe2jrLRWy9I5p9D2bvz1zQ4NwvWwWYRMSCJdsVmYVUb+m+hsyWQSDItEi3JtrmsLCfG3MsGACkLtEjUZ4kLc9usjpoPKd05O5hGtiJtdvuY8HiJ+QLJFeLkgiInRhVQXMD8QR2FYjlARoZbjTO3byAC8wUy6N6ysoDIiVE3JReWyNaMic2CUIChqjm9zK1Bdt0Soq7hvdHXUw9p3ZFtZw1cGonK+ne/AJPDQGjy1iJ4AAAAAElFTkSuQmCC');
					}
				}

				&.linkedin:before {
					background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAYAAACpSkzOAAAABHNCSVQICAgIfAhkiAAAAi9JREFUSEvdlsuLjmEYxn+X87kmpYiSQsxCpmZhQ2QhjXKqwcpGhPwdFsaGrYXNNMKGMk4Lpch/QEQshcHkMONw6/q633q/eedrNO9r46mv7/C8z/277+e+nuv5FBFXgT3AbEA0OwIYB24oIr4C85uNX4k2atBPYGZpyln8zupmNJTA+ETQL+CWSwU2AMeAxQ3AKqDbwBFJIxHhKk8C54A5NWEV0BlJF4qgEbEGeAwsaxp0yVVIslKIiD7gCrCgadA34CxwHVgPDACra0K8vLJ1he4/p+RdiZVXKLFg+rz55d+/Az/yuXnArEnOYwX0BrAgiuEe7QRGgWHgY06sArYAT4CHwDtgbu7CdmBtAos4FdBd4EBm6of8+TLgBPYBz3Ll1pT/IPAe8LFwhT74vanUnlLCFdCwpN0l1fUDQ8BrYJekpymSRcCYJG9Z24gIA08AF3M7J+3RX4ES5v5154F+6WQkuWdW6zrgEbA0s5h2RW74aeAUsDB7dVzS2wTZTdy7TXVBK4CbwObszRegT9KDBDmR+8C2uiD7oIWzMgPZhPslXUuQj8SdVOz0exQR7o1BrqwVGzgsyS7iHv1/IDu/K95Rt0dTbZ1B9wC7xD/t0ZQgl7u/ZEEHSxa0t2RBG/MWXl4Sw1Ggpbr0Od/SHeX9CXhR8pQuwMY6BjzPd0/bpW2cxc1r1b0CRkprPb+k09a1G1dz31oW5CoKcnOh2yN9MOg8cKiB67pTkv7fOPgHr9FvViwOWC8AAAAASUVORK5CYII=');
				}

				&.facebook:hover {
					color: blue;

					&:before {
						background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABsAAAAbCAYAAACN1PRVAAACr0lEQVRIiWN8763MgA+wh6Tb/H/7oujfq2cW/39+5/x754oASDmTiMQfRgGRL0zC4veZJOU7f26YtxKvQQwMDDgtA1ny9/q5VX+unpYkZAjMclZLt9KfmxdNIMkyVhvPA7+PbLcnxhJ0wKyi84HV2M7gx8ppDzEchMzhCM+SZ9E2fUauRSAACubfR3deZw9ICsdr2Z8rp08QG2x4LXxyl/P3kW1LQI5HFmeBMaBBJ0GMYczK2h+YlbTWMnJwXkEW/7l5UT+M/e/NCxaQDxkYGLhgYuA4AyWGn2tmHibGIlYbz4O/j2x3wCH9H12AzTV07q/dq1MYYMH49/q51cT6CI9FWMHvYzuSYMHJAvUVccGnpLX2792rKGLghPD3jzgDJBgx9Pz/+pnx37vXtQwMDCks/9++KCbWlehxBIrnnxvmEUy5/5498Abr/1wW8fzP1dNE+QykHpnDrKz9/u/dqwLE6mX69/EtP5GKKQKg6GL69+QeJz0sY/j53YQFnzyTjNJ3VkObKrh6tATAomuewaJlDC4E/n/5aP9r/8YAfOaxMAmL//n39iVWSxnZOX/iK1iRS3o211AdfBYxMLM8ZwJVE3gVEQn+f/+igk8lyGFMTJJyF6li2fs36rjkQNEBpplEJKfjUgRN1v+RMAoAJX2YHL7sw6ygfgpsGdh7wuJ/KPIWAcAkIVfDACsbWYzsFtLKIhZDm7s/18w8ArcMVCqzaJu+oLZFjFw8/1nU9JxhfHjlyaJjagGSpKZlbM5BRcjNA7hlIEE2t7BIalkIqsfQ8yhKswCUWNi8ou1A9Ra5loAcy+4bVwirMJEBRskBjUxBUPXx59xhu//fvjCiq8EFQIkBFEfYWlZgh+BrpIJqWFDF9/fetWBonsOoYv7//M4Oykeg5A1LdQMPGBgYAPBNHp2a9HsAAAAAAElFTkSuQmCC');
					}
				}

				&.facebook:before {
					background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABsAAAAbCAYAAACN1PRVAAAABHNCSVQICAgIfAhkiAAAAdFJREFUSEu1loFNwzAQRf+fADagTEA7QekElA1gAzYAJmAE2g1gAmAC2gnoCDDBoR/sKHFsx06FpSpqYuf5/v27mBgZZjYDcAXgEsCpu2rVwf12ALYkdc0Opp46yBOA9dhL3HPBb0m+p+ZHYWb2AOC+EBJOE+ya5Hf4oAczM8n0XBFNaj+SVFH2pA1hLy4/E4PqLZOsi26ELaxSug8AG2eQLuEt2OWO5MLfa2DODF+F4TySVE4Hw8wscltyamPwsFL5PkiqBKIjAZNRziUnK6Nqd+lpZjYHcOL+p2zfrBPsDoDqqWSsunVUkedXkmvBSiUEydC9imRZskutFUy1cFG6oDvPzIphTd4SSY2yj4kMwGoMtgegnDYj7HvOHOo6GjJKLvcNTJV+lpAxa/VA0hvX6lIZWQiW070Glm3e3iC5STWwnKv3JOeKTFp//rMbmxbn21Uub+0+jnCj2tXBw8aS6904pah1ZND7/xqxRkknmRDZD4CZ/6Z1YaoXyemb6iCNE2C9XhrKIrOoFKLAStjgCzE48LhPjj52gwZbCJN069gpK3eUU/2pVbVRFsC2WhM7WfUMEqszd9rSuVFuWiZgyrUKeiN75+o1GVlJkdfO+QVvrOTguswdbQAAAABJRU5ErkJggg==');
				}

				&.twitter:hover {
					color: blue;

					&:before {
						background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAATCAYAAAB7u5a2AAACNklEQVQ4jWN8763MQArgCM+S//vg5vp/Tx9o/H1yl5NJROIPs4bhkd9HtjuCjGEPSbdh+PndhCujfgLYcBZt0+fMqroFPzfMW4nPHpDBv4/uvA4yFF2OWUb5O4j+9+4lB7tvnCJnXPFDJpDAn6unJX7tXr2c3TeuAJ/hfx/c3IDNYLDck7ucIMxiZHsQpA4kxgST/P/1M+PPzYv6Wc2dz4FciM2AP5dP6hMKwT/nDtszScp3wA1nEhb/A5P8fXKv4a+96+6w2njuB4cfFIDY/799YSRkOJtbWCQseMFhzu4Z1f5z+7IKbIqZVXQ+MPIKvGHk5n3y+8h2B0KGg8wEEYJb7zKwgBj///wWZdE2fQEKe3SVf+9cEWBgYABhFUKmgkLg39uXcD7Y8N9HtycR42WCThYQ+cKAZDg4zFmMbA9RajAIMMupHEDmgw1nllaMZ5JR+k6p4Yz8wiswDP+xctpDNmsPTVYbz4PIKYcUwKys/QE9EyLS+bcvgb+PbLf/9/YlCzmGs2gZN2L4BLlsYTG0ufPn/BHSChuIvrt/zh9BSU2gpMiELABSAAoaRi6e/8QaDApGFjU9Z6xy6AKgjAIqeECZh6DBMkrf2VyCVUBxhk0eJXxBBdffJ/eyf25ZrAwqa/AZDIpAVhN7A1wGgwDjtym17b9P7S0hNiJBQcZq7Tnv1+7VKfjUgcIcHqFQV+f8e/1M6t+TeyjFKsiVjHyCb5kl5Fb/3L6skhhHgAynHWBgYAAAaLDo0WIzB2AAAAAASUVORK5CYII=');
					}
				}

				&.twitter:before {
					background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAATCAYAAAB7u5a2AAAABHNCSVQICAgIfAhkiAAAAWtJREFUOE+llYFNAkEQRf+vQK1ArEDtQCtQK9AOxAqEDixBK5AOlA6wAqUDrGDMI7uX2wPuVpiE5HLsvNn9+2fO+mdExLGkZ0lXki4k/Uh6tT0FFREjSSPbn04vZpImthd9tRL4I0G7S3PuWYKvMjwkrSTdUXFXgYh4kfQ4cNiJpBPb4zY85wCY2qZYERHBu6MB+C+SoUKGo9tpK2mto6Q32zxnLb8rrugyy5vht5LedyQiEwX4ceTesL1mEhn+IIkCN0PJA/8vbeOWAl6jZU3duW0sWsA5Lt49NJ5sY4gCTmOg7fmB9OYyG815SA0yloT+befU1isk6cLRiu7bN667DdjYJu0eb9/vQacfOHERBTwV4HKRZ6gTM2jJrNnW0Rvwlv40VWOrHaf5Sq2+MSoKzRMUGLLQUDioL+as27bjxooRAQhv1jqEwTS2zf30RiNLRLBrLoUPQNfv7JLZMrPN7K+KrZpXZVYs+gMuLoEUqXybwgAAAABJRU5ErkJggg==');
				}
			}
		}
	}

	.footer-section {
		padding-top: 2rem;
		padding-bottom: 1.5rem;

		&--icons {
            padding: 0;
            display: flex;
            flex-direction: row-reverse;
		}
	}
	
	.active {
		border-bottom: none;

		@include media(">=desktop"){
			border-bottom: 2px solid #ff4713;
		}
	}
    
    .copyright {
        padding: 2rem 4rem;

        p {
            font-size: 1rem;
            font-weight: 100;
        }
    }

		.footer-section {
			padding-top: 7rem;
			padding-bottom: 1.5rem;

			&--icons {
				padding: 0;
			}
		}

    .content-container{
        padding: 2rem 3rem;
        align-items: flex-end;
        @include media(">=desktop"){
			text-align: left;
		}
        
        .footer-logo {
            padding: 2rem;

            @include media(">=desktop"){
                padding: 0;
            }
        }

        .link-container {
            display: inherit;
			margin: 1rem 0;

			
			@include media(">=desktop"){
				display: flex;
			}

            .links {
                padding: 1.5rem 2rem;
				color: #fff;
				
				@include media(">=desktop"){
                    padding: 0 1rem;
                }

                @include media(">=widescreen"){
                    padding: 0 2rem;
                }
                a {
                    padding: 1rem .5rem;
                    color: #fff;
                    text-decoration: none;

                    &:hover {
                        color: #ef4b23;
                        cursor: pointer;
                        transition: ease-in;
                    }
                    
                }
            }
        }

	}
	
	.subscribe-section {
		height: 10rem;
		margin-right: 2rem;

		@include media(">=desktop"){
			margin-right: inherit;
			text-align: end;
			margin-right: 4rem;
		}

		p {
			font-size: 1.6rem;
			font-family: 'Gotham', sans-serif;
			margin-top: 0;
		}

		a {
			padding: 1rem 3rem;
			background-color: #F7901E;
			font-size: 1.4rem;
			text-decoration: none;
			color: #fff;
		}
	}
}

	.footer-link {
		text-decoration: none;
		color: #fff;

		&:hover {
		}
	}

	.footer-link-legal {
		text-decoration: none;
		color: $light-blue;
		font-weight: 100;

		&:hover {
		}
	}
