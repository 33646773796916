.cards-section {
	margin: 0 1.5rem;

	@include media(">=desktop") {
		margin: 2rem auto;
	}

	&--work {
		margin-top: 24rem;

		@include media(">=desktop") {
			margin-top: 7.5rem;
		}
	}

	&--home-animated {
		margin-top: 5rem;

		@include media(">=desktop") {
			margin-top: 7.5rem;

			.cards-container {
				max-height: 33.4rem;
			}
		}

		.img-container {
			overflow: hidden;
			height: inherit;
			transition: all 0.2s ease-in-out;
			max-height: inherit;

			// @include media(">=tablet"){
			// 	height: inherit;
			// }

			// @include media(">=desktop"){
			// 	height: 26.6rem;
			// }

			// &:hover {
			// 	height: inherit;

			// 	@include media(">=desktop"){
			// 		height: 26.6rem;
			// 	}
			// }

			img {
				width: 100%;
				transition: all 0.2s ease-in-out;

				&:hover {
					transform: scale(1.025);
				}
			}
		}
	}

	&--animated {
		.img-container {
			overflow: hidden;
			transition: all 0.2s ease-in-out;
			max-height: inherit;

			img {
				width: 100%;
				transition: all 0.2s ease-in-out;

				&:hover {
					transform: scale(1.025);
				}
			}
		}
	}

	.cards-container {
		flex-wrap: wrap;

		p {
			font-weight: bold;
			font-size: 1.4rem;
			font-family: 'Gotham', sans-serif;
			margin-top: 0.5rem;

			@include media(">=tablet") {
				font-size: 2.5rem;
            }

			@include media(">=desktop") {
                font-size: 1.4rem;
            }
		}

		.date-breadcrumb {
			color: #939393;
			margin-bottom: .5rem;
			font-size: 1.2rem;
			font-family: 'Lexia';
		}

		h4 {
			font-size: 1.4rem;
			font-family: 'Gotham', sans-serif;
			margin-bottom: 0rem;
			margin-top: 1rem;
			margin-top: 0rem;
		}

		a {
			color: #000;
			cursor: pointer;
			text-decoration: none;
			font-size: 1.4rem;

			@include media(">=tablet") {
				font-size: 2.5rem;
            }

			@include media(">=desktop") {
                font-size: 1.4rem;
            }
        }
        
        .column {
            margin-bottom: 2rem;

            @include media(">=desktop") {
                margin-bottom: 4rem;
            }
        }
	}

	.img-container {
		img {
			width: 100%;
		}
	}

	.color-container {
		width: 100%;
		padding: 5rem;
		background-color: #ef4b23;
		color: #fff;

		p {
			font-family: Lexia;
			font-weight: 500;
		}
	}

	.anchor-container {
		margin: 5rem auto;
		min-height: 6rem;
		width: 100%;
		text-align: center;

		a {
			font-size: 1.6rem;
			color: #ef4b23;
			font-weight: 600;
			text-decoration: none;

			@include media(">=tablet") {
				font-size: 2.6rem;
			}

			@include media(">=desktop") {
				font-size: 1.6rem;
			}

			&:hover {
				border-bottom: none;
                border-bottom: 2px solid #ef4b23;
                transition: ease-in;
			}
		}
	}
}

.stats-section {
	width: 100%;
	@include media(">=desktop") {
		max-width: 44rem;
	}
}

.stats-container {
	padding: 3rem 0rem 3rem 2rem;
	color: #fff;
    margin-bottom: -20px !important;
    background-color: #005e61;
	display: flex;
	flex-wrap: wrap;

	@include media(">=tablet") {
		padding: 3rem;
	}

	@include media(">=desktop"){
		padding: 3rem;
	}

	&--alt {
		padding: 0 0 3rem 0;
	}

	ul {
		margin: 0;
		padding: 0;
		list-style-type: none;
		width: 100%;
	}

	li {
		width: 100%;
    }
    
    a {
        color: #fff;
    }

	strong,
	span {
		display: inline-block;
		vertical-align: top;
		font-size: 1.6rem;
		padding-top: 1rem;
		padding-bottom: 1rem;
	}

	strong {
		text-align: right;
		padding-right: 2rem;
		width: 40%;
		font-family: 'Gotham', sans-serif;
	}

	span {
		padding-left: 2rem;
		border-left: 1px solid #fff;
		width: 60%;
		font-family: 'Lexia';
	}
}

.about-anchor {
	width: 14rem;
	position: relative;
	right: 0;
	bottom: 4rem;
    margin-right: 2rem;
    padding-left: 1rem;
	z-index: 9999;
	display: none;

	a {
		color: #000;
		text-decoration: none;
		p {
			font-size: 1.4rem;
			font-weight: 800;
            text-align: left;
            font-family: 'Gotham', sans-serif;
		}

		&:hover {
			color: #f7901e;
			text-decoration: underline;
		}
	}

	&::before {
		content: "";
		width: 2rem;
		height: 2rem;
		margin-top: 0;
		padding-left: 1rem;
		right: 7px;
		background-repeat: no-repeat;
		position: absolute;
		background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAALCAYAAAByF90EAAAABHNCSVQICAgIfAhkiAAAAR1JREFUKFOF0rFKA0EQBuD/R+7cnCKooKjkAlqk8CEOUtn4AEoQS7HxfAwtTBRrsQ34AiKCh73YapkQIQERLLwNSkZuTyMmd5tp59+PmWEJS0l91o8x/QDp73mH7StblnlNOfKWtTv/DHJKIDIBbE2GrUZePhOS+sKihhsBLAODSI/ApgqbN1nYCJQiKgJQhkjivAOc+Xmci/2D4vOlEr6ca4Ok9aTwGmjMHQPcsWEDyCCfTgSi9IfogGG3IwLq0+KlDTOQDfm9xziMw4gAjwXojWSS4aPaMMa14h3IIHlkEBcV7jffcr9FuuYFwN00Ix/K0WvUNX+1T7kH2B2HjKwpqAqx7YWthrlR78RfdxVebJNkrnm2UikctG+T3jcjQ4jN/XSY9AAAAABJRU5ErkJggg==");

		@include media(">=desktop") {
			margin-top: 17px;
		}
	}

	@include media(">=desktop") {
		position: fixed;
		display: block;
	}
}

.team-card {
	max-width: 35rem;
	margin: auto;
}

.anchor-background {
    background-color: #fff;
}
