.card-gallery {
	max-width: 100%;
	flex-wrap: wrap;
	margin: 3rem auto;
	padding: 0 3rem;

	.img-container {
		img {
            filter: grayscale(100%);
            width: 100%;
		}
	}

	.teams-filters{
		margin: 4rem 0;

		a {
			color: #000;
			text-decoration: none;
			padding: 1rem;
			border: 3px solid #E3D6C6;
			cursor: pointer;
			font-size: 1.4rem;
			font-weight: bold;
			margin: 0 3rem 0 0;

			&:hover {
				background-color: #F7901E;
				border: 3px solid #F7901E;
				color: #fff;
			}
		}
	}

	.columns {
		@media (min-width: 768px) {
			display: flex;
			flex-wrap: wrap;
		}
	}

	.card {
		width: 100%;

		@media (min-width: 768px) {
			flex-basis: 50%;
		}

		@media (min-width: 1024px) {
			flex: 0 0 25%;
		}

		&:hover {
			.img-container {
				img {
					filter: none;
				}
			}
        }
        
        .card__content {
            display: none;
        }

		&.is-open {
            min-height: 45rem;
            
            .card__content {
                display: block;
            }

			.img-container img {
				filter: none;
			}

			.card__title {
				&::before {
					content: " ";
					position: absolute;
					bottom: -2.6rem;
					left: 0;
					right: 0;
					width: 0;
					height: 0;
					margin: auto;
					border-color: #fff transparent transparent;
					border-style: solid;
					border-width: 2.6rem 2.6rem 0;
					z-index: 99999;
				}

				&:hover::before {
					border-color: #e6e6e6 transparent transparent;
				}
			}

			.card__content {
				opacity: 1;
				z-index: 9998;
				padding: 1rem;
                
                h3 {
                    margin-bottom: 1rem;
                }
                h5 {
                    margin-top: 0;
					margin-bottom: 1rem;
					font-weight: 500;
				}
				
				@include media(">=desktop") {
					padding: 5rem;
				}
			}

			.card__open-button {
				border: 2px solid #f7901e;
				padding: 1rem 1.5rem;
				background-color: #f7901e;

				.glossary-icon::before {
					display: none;
				}

				&:hover {
					background-color: #fff;
					border-color: #f7901e;

					.glossary-icon,
					.glossary-icon::before {
						background: #000;
					}
				}
			}
		}
	}

	.card__title {
		position: relative;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 1.4em 0;
		border-bottom: 1px dotted #636569;
		background: #fff;
		font-weight: bold;
		font-size: 1.5rem;

		/* Icon */
		&:hover {
			background: darken(#fff, 10%);

			> .glossary-icon,
			.glossary-icon:before {
				background: black;
			}
		}
	}

	.card__open-button {
		border: 2px solid #e3d6c6;
		padding: 1rem 1.5rem;
		background-color: #fff;
		transition: all 0.2s linear;

		&:hover {
			background-color: #f7901e;
			border-color: #f7901e;

			.glossary-icon,
			.glossary-icon::before {
				background: #fff;
				transition: background-color 0.2s linear;
			}
		}
	}

	.card__close-button {
		position: absolute;
		top: 1rem;
		right: 2rem;
		border: none;
		background: none;
		font-size: 1.6rem;
		width: 12rem;
		height: 3rem;
		color: #fff;

		&:hover {
			.close-icon,
			.close-icon::before {
				background: #f7901e;
				transition: background-color 0.2s linear;
			}
		}
	}

	.glossary-icon {
		background: #000;
		display: block;
		height: 2px;
		width: 12px;
		position: relative;
		transition: 0.2s ease-out;
		transform: rotate(-180deg);

		&::before {
			background: #000;
			content: "";
			display: block;
			height: 100%;
			width: 100%;
			position: absolute;

			transition: all 0.2s ease-out;
			transform: rotate(90deg);
		}
	}

	.close-icon {
		background: #fff;
		display: block;
		height: 2px;
		width: 3rem;
		margin-bottom: 1rem;
		position: absolute;
		top: 50%;
		right: 0;

		transition: .2s ease-out;
		transform: rotate(45deg);

		&::before {
			background: #fff;
			content: "";
			display: block;
			height: 100%;
			width: 100%;
			position: absolute;

			transition: all 0.2s ease-out;
			transform: rotate(270deg);
		}
	}

	.card__content {
		opacity: 0;
		position: absolute;
		left: 0;
		top: auto;
		padding: 0 0 2rem 0;
		background: #005e61;
		width: 100%;
		color: #fff;
		transition: opacity 0.2s linear;
		z-index: 0;
		font-weight: normal;

		@include media(">=desktop") {
			left: 3rem;
			width: 96%;
		}

		&-container {
			display: block;

			@include media(">=desktop") {
				display: flex;
			}
		}

		&-copy {
			flex: 1 1 66%;
			column-count: inherit;

			p {
				margin-top: 0;
			}

			@include media(">=desktop") {
                column-count: 2;
                
                p {
                    margin-right: 8rem;
                }
			}
		}

		.card__email {
			flex: 1 1 33%;
			display: flex;
			flex-direction: column;
			align-items: center;

			h5 {
				margin-bottom: 1rem;
			}

			a {
				color: #f7901e;
				text-decoration: none;
				margin-bottom: 1rem;
				font-weight: bold;
				font-size: 1.5rem;

				&:hover {
					text-decoration: underline;
				}
			}
		}

		&.active {
			display: block;
		}
	}
}
