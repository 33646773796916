.home-hero-content-mobile {
	width: 100%;
	background: #ef4b23;
	position: relative;
	color: #fff;
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin: 0;
	padding: 2rem;

	@include media(">=tablet") {
		padding: 2rem 20rem;
	}

	@include media(">=desktop") {
		display: none;
	}

	h1 {
		font-family: industry-inc-outline ,sans-serif;
		font-size: 4.5rem;
		line-height: 1;
	}

	h3 {
		font-family: "Gotham", sans-serif;
		margin-bottom: 0.5rem;
	}

	p {
		font-family: Lexia;
		line-height: 1.5;
		margin-bottom: 3rem;
	}

	a {
		color: #fff;
		font-size: 1.5rem;
		font-weight: 800;
		text-decoration: none;
		font-weight: bold;
		width: 8rem;
		font-family: "Gotham", sans-serif;

		&:hover {
			border-bottom: 2px solid#ff4713;
			transition: ease-in;
		}
	}
}

.hero-anchor {
	font-size: 1.2rem;
	position: absolute;
	right: 0;
	bottom: -5.5rem;
	display: none;
	background-color: inherit;
	color: #000;
	font-family: "Lexia";
	padding: 1.5rem 2.5rem;
	text-decoration: none;
	z-index: 9999;

	@include media(">=desktop") {
		bottom: -4.5rem;
	}

	&::before {
		content: "";
		background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAALCAYAAAByF90EAAAAwElEQVQokWMAAWVl5Ze2trZbGUgAISEh0QICAr9iYmL0wbo0NTXvMzAw/AdhYg0LCAjIYWJiAuvh5ub+U1VVJcmgp6d3E2YQMYaBDGFkZISr5+Hh+V1VVSUOlkQ3zNzc/CgxhggKCv6MjIyUQ1FEyDBshiQnJwthdbaBgcFVbIaRZAgMQDXDNamrqz8m2RBchpFlCAyYmJicRTaEh4fnD8mGwICqquozkCGsrKz/yDYEBiwtLQ+lpaVx4VXEwMAAAAjBZaY/FALiAAAAAElFTkSuQmCC");
		position: absolute;
		transform: rotate(-90deg);
		right: 0;
		background-repeat: no-repeat;
		height: 1rem;
		width: 2rem;
	}
}

.hero {
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	position: relative;

	&--home {
		height: auto;

		.container {
			max-width: 52rem;
			margin-left: 1rem;
			position: absolute;
			color: #fff;
			display: none;
			flex-direction: column;
			justify-content: center;
			margin: 16rem 0 0 1rem;

			@include media(">=desktop") {
				margin: 16rem 6rem;
				display: flex;
				top: 0;
			}

			h1 {
				font-family: industry-inc-outline ,sans-serif;
				font-size: 5.5rem;
				line-height: 1;

				@include media(">=desktop") {
					font-size: 6.5rem;
				}
			}

			h3 {
				font-family: "Gotham", sans-serif;
				margin-bottom: 0.5rem;
			}

			p {
				font-family: Lexia;
				line-height: 1.5;
				margin-bottom: 3rem;
			}

			a {
				color: #ff4713;
				font-size: 1.5rem;
				font-weight: 800;
				text-decoration: none;
				font-weight: bold;
				width: 8rem;
				font-family: "Gotham", sans-serif;

				&:hover {
					border-bottom: 2px solid#ff4713;
					transition: ease-in;
				}
			}
		}
	}

	.splide__track {
		padding-bottom: 5rem;
	}

	.splide__arrow {
		width: 10%;
		bottom: 0;
		top: 0;
		z-index: 9998;
		background-color: transparent;
		position: absolute;
		border: none;
		fill: #EF4B23;

		@include media(">=desktop") {
			width: 3.5%;
		}
	}

	.splide__arrow--prev {
		transform: rotate(-180deg);

		svg {
			height: 3rem;
			width: 3rem;

			@include media(">=desktop") {
				width: 4rem;
				height: 4rem;
			}
		}
	}

	.splide__arrow--next {
		right: 0;

		svg {
			height: 3rem;
			width: 3rem;

			@include media(">=desktop") {
				width: 4rem;
				height: 4rem;
			}
		}	
	}

	.splide__slide {
		margin: 0 .25rem;
		max-width: 90vw;

		@include media(">=desktop") {
			margin: 0 .5rem;
			max-width: 90vw;
		}

		.hero-body {
			display: none;

			@include media(">=desktop") {
				display: block;
			}
		}

		img {
			max-width: 140.4rem;
			height: 100%;
			object-fit: cover;

			@include media(">=desktop") {
				object-fit: inherit;
			}
		}

		&::before {
			content: '';
			position: absolute;
			top: 0;
			bottom: 0;
			right: 0;
			left: 0;
			background-color: rgba(255, 255, 255, .75);
		}
	}
	.splide__slide.is-visible {
	
		&::before {
			content: '';
			position: absolute;
			top: 0;
			bottom: 0;
			right: 0;
			left: 0;
			background-color: rgba(255, 255, 255, 0);
			transition: step-start;
		}

		.hero-anchor {
			display: block;
		}
	}

	&__anchor {
		position: absolute;
		display: none;
		bottom: 5%;
		left: 5%;
		animation: bounce 3s 5;
		-webkit-animation: bounce 2s 5;
		-moz-animation: bounce 2s 5;
		-o-animation: bounce 2s 5;

		@include media(">=desktop") {
			left: 2.5%;
			display: block;
		}
	}

	&--work {
		height: 46rem;

		.container {
			height: 46rem;

			position: relative;
			color: #fff;
			display: flex;
			flex-direction: column;
			text-align: center;
			justify-content: flex-end;

			&--work {
				height: 15rem;
				@include media(">=desktop") {
					height: 20rem;
				}
			}

			&--headline {
				height: 12rem;
				margin: 4rem 0 0 0;

				@include media(">=tablet") {
					height: 32rem;
					margin: 0;
				}

				@include media(">=desktop") {
					height: 28rem;
					margin: 0;
				}
			}

			h1 {
				font-size: 7rem;
				font-family: industry-inc-outline ,sans-serif;

				@include media(">=desktop") {
					font-size: 10rem;
				}
			}

			p {
				font-family: "Gotham", sans-serif;
			}

			a {
				color: #f7901e;
				font-size: 1.5rem;
				text-decoration: none;
				font-weight: 500;
				font-family: "Gotham", sans-serif;
			}

			@include media(">=desktop") {
				height: 45rem;
				text-align: left;
			}
		}

		.content-container {
			position: absolute;
			margin-top: 20rem;
			z-index: 9995;

			@include media(">=desktop") {
				position: relative;
				margin-top: inherit;
				z-index: inherit;
			}

			.container {
				height: 18rem;
				color: #000;
				margin: 0;
				padding: 1rem;

				@include media(">=tablet") {
					margin: 0 5rem;
					padding: inherit;
				}

				@include media(">=desktop") {
					height: 45rem;
					color: #fff;
					margin: inherit;
					padding: inherit;
				}

				p {
					font-weight: 500;
					font-size: 1.6rem;

					@include media(">=tablet") {
						font-size: 2rem;
					}

					@include media(">=desktop") {
						font-size: 1.6rem;
					}
				}
			}
		}
	}

	&--about {
		height: 20rem;

		@include media(">=desktop") {
			height: 46rem;
		}

		.container {
			height: 18rem;

			position: relative;
			color: #fff;
			display: flex;
			flex-direction: column;
			text-align: center;
			justify-content: flex-end;

			&--work {
				height: 20rem;
			}

			&--headline {
				height: 12rem;
				margin: 4rem 0 0 0;

				@include media(">=desktop") {
					height: 28rem;
					margin: 0;
				}
			}

			h1 {
				font-size: 7rem;
				font-family: industry-inc-outline ,sans-serif;
				font-weight: bold;

				@include media(">=desktop") {
					font-size: 10rem;
				}
			}

			p {
				font-family: "Gotham", sans-serif;
			}

			a {
				color: #f7901e;
				font-size: 1.5rem;
				text-decoration: none;
				font-weight: 500;
				font-family: "Gotham", sans-serif;
			}

			@include media(">=desktop") {
				height: 45rem;
				text-align: left;
			}
		}

		.content-container {
			max-height: 10rem;

			.container {
				height: 18rem;

				@include media(">=desktop") {
					height: 45rem;
				}

				p {
					font-weight: 500;
					font-size: 1.6rem;
				}
			}
		}

		.filter-box {
			width: 160px;
			text-align: right;
			background-color: #f7901e;
			position: absolute;
			right: 0;
			top: 2rem;
			padding: 1rem;
			transform: translate(0, 65%);
			display: none;

			a {
				text-decoration: none;
				p {
					font-weight: 600;
					color: #fff;
					font-size: 1.2rem;
					font-family: "Gotham", sans-serif;
				}
			}

			@include media(">=desktop") {
				display: block;
			}
		}
	}

	&--news {
		.hero-body {
			margin: 0 1.5rem;
			padding-top: 8.4rem;

			@include media(">=desktop") {
				min-height: 29rem;
				margin: 0 0 10rem;
			}
		}

		.accordion {
			position: relative; //overflow bug
		}

		.column {
			margin-top: auto;

			&--news {
				padding-right: 0;
			}
		}

		.input-container {
			width: 100%;
			margin: auto;
			padding-bottom: 2rem;
			position: relative;

			@include media(">=desktop") {
				padding: 0;
			}

			&::after {
				content: "";
				position: absolute;
				margin-top: 2rem;
				margin-right: 1.2rem;
				width: 3rem;
				height: 3rem;
				right: 0;
				background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABHNCSVQICAgIfAhkiAAAAghJREFUSEvFVcFt20AQnL07vSVXEL/ysQHLP0E6IlQFtjuwK0g6sNNBUkHsCqIgBYQGD4R+cYAUIFdg+RvecYMVLIGiKNIG5GQ/fNzdzs7ucJbwykGvnB+NANbaGMA7ADER9aUYZr4DkAC4dc7JtzFqAeI47nnvvxDRadNrZp4YYy6SJJlvu7cBMBwO+1rrrwD2ATwy8zUASSSVw3svTE6J6BxAF8AshHCWZdnivBprAFJ5COGnJGfmW+/9+XQ6ndU9HAwG+8aYayKSFs601sd1TNYAoiiaADiR5M456X9rWGsTAZF2OefOtjKQgRLRD2lLnuf9bZVXEwiTTqcj7eky87g6+BUDa+0VEV0y82fn3IfW0ksXrLWfiOg9M390zl2V35YBllQ3qmgDW7Kva+0KIIoikVpXa73XJLs6sCdxPACYp2m6V8tgRwCPaZr2/k+L/sWQlzKd53l+/EKZys/Za5Sp9K30oyXOuXGbeuR8NBr9VkodALhJ01TsYy02rMJ7f0dEb5g58d5fNFmF1vq7UuqQmf8URfE2y7L7RgA5FLNTSk0ERGQHYGF2Wutfch5COBKzAyDV9oqiyJVSHbFxY8y4KvGtdh1CkMQnLW26CSFcKqW+EdFRHchzFo6YXt3CkTktFs7T/hAn2ADZ2cqsgKxEsjOAMhOZ3dLudwrQutGeo/uX3vkLAQdAKN1avpIAAAAASUVORK5CYII=");
				background-repeat: no-repeat;
			}
		}

		.input--search {
			position: relative;
			height: 100%;
			width: 100%;
			font-size: 1.6rem;
			font-family: "Lexia";
			padding: 2.2rem 1rem;
			font-style: italic;
			outline: none;
		}

		h1 {
			padding: 5rem 0;
			font-size: 10rem;
			font-weight: 400;
			font-family: "industry-inc-outline", sans-serif;
			color: black;
			text-align: center;

			@include media(">=desktop") {
				padding: 0;
				text-align: left;
				line-height: 0.4;
			}
		}

		a {
			font-size: 1.5rem;
			text-decoration: none;
			font-weight: bold;
		}
	}

	&--search {
		min-height: 20rem;

		.header-container {
			display: block;

			.container {
				height: 12rem;

				@include media(">=desktop") {
					height: 32rem;
				}
			}

			@include media(">=desktop") {
				display: inline-flex;
			}
		}

		.container {
			height: 22rem;
			position: relative;
			color: #fff;
			display: flex;
			text-align: center;
			flex-direction: column;
			justify-content: flex-end;

			@include media(">=desktop") {
				text-align: left;
			}

			h1 {
				font-size: 7rem;
				font-weight: 400;
				font-family: industry-inc-outline ,sans-serif;
				color: black;

				@include media(">=desktop") {
					font-size: 10rem;
				}
			}

			a {
				font-size: 1.5rem;
				text-decoration: none;
				font-weight: bold;
			}
		}
	}

	&--contact {
		height: 46rem;

		.container {
			height: 45rem;
			max-width: 52rem;
			position: relative;
			color: #fff;
			display: flex;
			flex-direction: column;
			justify-content: flex-end;

			h1 {
				font-size: 10rem;
				font-family: industry-inc-outline ,sans-serif;
			}

			a {
				color: #f7901e;
				font-size: 1.5rem;
				text-decoration: none;
				font-weight: bold;
			}
		}
	}
	img {
		width: 100%;
	}
}

.search-section {
	width: 100%;

	.search-container {
		width: 100%;

		@include media(">=desktop") {
			margin: 0 auto;
		}

		.content-container {
			display: flex;
			align-items: flex-end;
			text-align: center;

			@include media(">=desktop") {
				text-align: inherit;
			}

			.input-container {
				height: 4rem;
				width: 90%;
				margin: auto;
				position: relative;

				@include media(">=desktop") {
					width: 80%;
					margin: inherit;
				}
				&::after {
					content: "";
					position: absolute;
					margin-top: 8px;
					margin-right: 12px;
					width: 30px;
					height: 30px;
					right: 0;
					background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABHNCSVQICAgIfAhkiAAAAghJREFUSEvFVcFt20AQnL07vSVXEL/ysQHLP0E6IlQFtjuwK0g6sNNBUkHsCqIgBYQGD4R+cYAUIFdg+RvecYMVLIGiKNIG5GQ/fNzdzs7ucJbwykGvnB+NANbaGMA7ADER9aUYZr4DkAC4dc7JtzFqAeI47nnvvxDRadNrZp4YYy6SJJlvu7cBMBwO+1rrrwD2ATwy8zUASSSVw3svTE6J6BxAF8AshHCWZdnivBprAFJ5COGnJGfmW+/9+XQ6ndU9HAwG+8aYayKSFs601sd1TNYAoiiaADiR5M456X9rWGsTAZF2OefOtjKQgRLRD2lLnuf9bZVXEwiTTqcj7eky87g6+BUDa+0VEV0y82fn3IfW0ksXrLWfiOg9M390zl2V35YBllQ3qmgDW7Kva+0KIIoikVpXa73XJLs6sCdxPACYp2m6V8tgRwCPaZr2/k+L/sWQlzKd53l+/EKZys/Za5Sp9K30oyXOuXGbeuR8NBr9VkodALhJ01TsYy02rMJ7f0dEb5g58d5fNFmF1vq7UuqQmf8URfE2y7L7RgA5FLNTSk0ERGQHYGF2Wutfch5COBKzAyDV9oqiyJVSHbFxY8y4KvGtdh1CkMQnLW26CSFcKqW+EdFRHchzFo6YXt3CkTktFs7T/hAn2ADZ2cqsgKxEsjOAMhOZ3dLudwrQutGeo/uX3vkLAQdAKN1avpIAAAAASUVORK5CYII=");
					background-repeat: no-repeat;
				}
			}

			input {
				position: relative;
				height: 100%;
				width: 100%;
				font-size: 1.6rem;
				font-family: "Lexia";
				padding-left: 1rem;
				font-style: italic;
			}

			a {
				font-size: 14px;
				padding: 1rem 2rem;
				text-decoration: none;
				color: black;
				border: 3px solid #e3d6c6;
				margin: 0 1rem;
				font-weight: bold;
			}

			h4 {
				margin: 0.5rem;
			}
		}
	}
}

.splide {
	&.hero {
		.splide__pagination {
			margin: 1.8rem auto;
			padding: 0;

			@include media(">=desktop") {
				position: absolute;
				bottom: 2rem;
				left: 50%;
				transform: translateX(-50%);
				width: auto;
			}

			&__page {
				min-width: 6rem;
				height: 0.5rem;
				border: none;
				margin: 0 0.5rem 1rem;
				padding: 0;
				background-color: #c6d4e2;
				border-radius: 0;

				&.is-active {
					background-color: #f7901e;
				}
			}
		}
	}
}

/* Film Roll */
.film_roll {
	position: relative;

	.film_roll_child {
		height: 100%;
		margin: 0 0.25rem;
		opacity: 0.18;
		transition: opacity 1s ease-in-out;
		-moz-transition: opacity 1s ease-in-out;
		-webkit-transition: opacity 1s ease-in-out;

		&.active {
			opacity: 1;
		}

		img {
			display: inline;
			max-height: 200px;
			max-width: none;
			
			@media screen and (min-width: 1440px) {
					max-height: 730px;
					min-height: 730px;
			}
		}
	}

	.arrow {
		width: 10%;
		bottom: 0;
		top: 0;
		z-index: 9998;
		background-color: transparent;
		position: absolute;
		border: none;
		fill: #ef4b23;

		@include media(">=desktop") {
			width: 3.5%;
		}

		svg {
			height: 3rem;
			width: 3rem;

			@include media(">=desktop") {
				width: 4rem;
				height: 4rem;
			}
		}
	}

	.arrow--prev {
		transform: rotate(-180deg);
	}

	.arrow--next {
		right: 0;
	}

	.hero__anchor {
		position: absolute;
		display: none;
		bottom: 5%;
		left: 5%;
		animation: bounce 3s 5;
		-webkit-animation: bounce 2s 5;
		-moz-animation: bounce 2s 5;
		-o-animation: bounce 2s 5;

		@include media(">=desktop") {
			left: 2.5%;
			display: block;
		}
	}
}

.film_roll_prev,
.film_roll_next {
	text-decoration: none !important;
	border: 0 !important;
	background: none !important;
	color: #ef4b23 !important;
}
