.forms-section {
	position: relative;
	width: 100%;
	.forms-container {
		width: 100%;
		margin: auto;

		.column {
			margin: 0 2rem;
		}

		p {
			font-family: Lexia;
        }
        
        @include media(">=desktop") {
            width: 80%;
        }
	}

	input {
		height: 50px;
		border: 1px solid #707070;
        border-radius: 0;
        font-size: 1.3rem;
        font-family: 'Lexia';

		&.file {
			border: none;
		}
    }
    
    option {
        font-size: 1.3rem;
        font-family: 'Lexia';
    }

	select {
		height: 50px;
		width: 100%;
        font-style: italic;
        font-size: 1.3rem;
        font-family: 'Lexia';
	}

	.field {
		margin: 3rem 0;
	}

	textarea {
		border: 1px solid #707070;
        border-radius: 0;
		font-family: 'Lexia';
		font-size: 1.3rem;
	}

	::placeholder {
		font-style: italic;
        color: #3c3c3c;
        font-size: 1.3rem;
	}

	.form-submit {
		color: #ef4b23;
		text-align: right;
		text-decoration: none;
		font-family: 'Gotham', sans-serif;
		font-size: 16px;
		font-weight: 600;
		cursor: pointer;
		background: white;
		border: none;
		padding: 0;
		height: inherit;

		&:hover {
			border-bottom: 2px solid #ef4b23;
		}

		&:focus {
			outline: none;
		}
	}
}

.styled-checkbox {
	position: absolute; // take it out of document flow
	opacity: 0; // hide it

	& + label {
		position: relative;
		cursor: pointer;
		padding: 0;
		transition: all .1s linear;
	}

	// Box.
	& + label:before {
		content: "";
		margin-right: 1rem;
		display: inline-block;
		vertical-align: bottom;
		width: 1.5rem;
		height: 1.5rem;
		background: none;
		border: 1px solid rgb(71,73,76);
		transition: all .1s linear;
	}

	// Box hover
	&:hover + label:before {
		background: #EF4B23;
	}

	// Box focus
	&:focus + label:before {
		box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.12);
	}

	// Box checked
	&:checked + label:before {
		background:rgb(247,144,30);
	}

	// Disabled state label.
	&:disabled + label {
		color: #b8b8b8;
		cursor: auto;
	}

	// Disabled box.
	&:disabled + label:before {
		box-shadow: none;
		background: #ddd;
	}

	// Checkmark. Could be replaced with an image
	// &:checked + label:after {
	// 	content: "";
	// 	position: absolute;
	// 	left: 5px;
	// 	top: 9px;
	// 	background: white;
	// 	width: 2px;
	// 	height: 2px;
	// 	box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white, 4px -6px 0 white, 4px -8px 0 white;
	// 	transform: rotate(45deg);
	// }
}
