.article-section {
    width: 100%;
    position: relative;

    .article-container {
        max-width: 80rem;
        margin: 5rem auto;

        span {
            color: #EF4B23;
        }

        p {
            font-family: Lexia;
        }

        .img-container {

            img { 
                width: 100%;
            }
        }

        .text-container {
            padding: 2rem;

                h3 {
                    font-size: 3rem;

                    @include media(">=desktop") {
                        font-size: 3.5rem;
                    }
                }

            @include media(">=desktop") {
				padding: inherit;
			}
        }
    }
}

.article-anchor {
    width: 12rem;
    position: relative;
    left: 0;
    margin-left: 2rem;

    a {
        color: #000;
        text-decoration: none;
        p {
            font-size: 1.3rem;
            font-weight: 800;
            text-align: right;
        }
        
        &:hover {
            color: #F7901E;
            text-decoration: underline;
        }
    }
    

    &::before {
        content: '';
        width: 1rem;
        height: 2rem;
        margin-top: 0;
        background-repeat: no-repeat;
        position: absolute;
        background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAsAAAASCAYAAACNdSR1AAAABHNCSVQICAgIfAhkiAAAAUBJREFUKFN90j9PwlAUBfBzELQVpg4aFWHTRAd3o3Ey8Uu4MTkVXXTTTRephs3Nxc3FmMDIJ9DVhaGyQPyzQRHsNe9RCYRXOvXd/M67r7ePmPKIt7AYwHqgoGAV/TrjbARrANYhqFtJ2TXiMTjY7c1CsDeBY6Hbao7hTnkpj36qqluP7ui2mmo5xBr2UjUQeRMc4m7Z2Qx7medpUOPurbMR/qZfQM6qggCvNoIDRq1Hp8V2afWTpDOA8mHPco1H/rdppPwprRz2kbinPj1VwJt334tGrIqdUrZAJsoCzEUdjIHhNAIvty/AE6IAgSvL9U/Hzjy6GATkEWBG1wUXdtE//zcTf7B9s7xNmamaAsa7MREAzmzXv4y9dR0vtwORCsi0OkYC4VYsVkB3CJMVMDyx3cbdVKy/8Trr8Ljxpd7/AMZsgmsLQ/XtAAAAAElFTkSuQmCC');

        @include media(">=desktop") {
            margin-top: 11px;
        }
    }

    @include media(">=desktop") {
        position: fixed;
    }
}