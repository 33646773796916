﻿
.primary-navigation {
	background-color: transparent;
	position: absolute;
	color: #fff;
	width: 100%;
	flex-wrap: wrap;
    align-items: center;
	z-index: 9999;

	@include media(">=desktop") {
		padding: 0 3rem;
	}

	.nav-container {
		display: flex;
		flex-wrap: wrap;
		align-items: center;

		@include media(">=desktop") {
			position: static;
		}
	}

	.contact-mobile {
		width: 100%;
		min-height: 5rem;
		text-align: center;
		font-weight: bold;
		font-size: 2rem;
		background-color: $darker-blue;
		padding: 1rem 0 0;

		&:before {
			display: inline-block;
			content: " ";
			width: 2.2rem;
			height: 2.4rem;
			margin-right: 1rem;
			background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAWCAYAAAAfD8YZAAAABHNCSVQICAgIfAhkiAAAAO9JREFUOE/tlDFuAjEQRefb9KxvQMENaCPBEZIe0OYGoGy4BBvBDbICRE9HCTfITbxIlNg/shDWJorQbhkJN7Y082bG4/GH/RilIBYikki9VRKcmrd1ATsfldLSHTMtyhtr8+EzPS9a6553l52Zbb+ibZEmcN4m2Qoo8zG14qCa1DnXFdU60/snJbJXGucfdo9DhEke61V89QLQj3A4NIFDtQ+4QcceDfufE9bgiaPr3Y9h83EBYTvJ1i+/g98dEjsfDgD1GSDSv5r3zaEaIMJ/yVBwDJnDbrJVWgVtVYaCAIqXJYB2nbuTPImSSRDAbxk12xc4D06vAAAAAElFTkSuQmCC');
			background-position: center;
			background-size: contain;
			background-repeat: no-repeat;
			vertical-align: middle;
		}

		a {
			color: $orange;
			text-decoration: none;
		}

		@include media(">=tablet") {
			display: none;
		}
	}

	.logo {
		flex: 1 1 50%;
		padding-left: 1rem;
		display: none;

		@include media(">=desktop") {
			flex: 0 0 10%;
			order: 1;
			padding: 0;
			display: block;
		}

		img {
			width: 100%;

			@include media(">=tablet") {
				max-width: 20rem;
			}

			@include media(">=desktop") {
				max-width: 25rem;
			}
		}
	}

	.logo-mobile {
		flex: 1 1 50%;
		padding: 1.5rem 1rem 1.5rem 2rem;

		@include media(">=desktop") {
			display: none;
		}
	}

	.search {
		flex: 0 0 25%;

		@include media(">=desktop") {
			flex: 0 0 3%;
			order: 3;
		}
	}

	.menu {
		display: flex;
		flex: 0 0 100%;
		flex-direction: column;
		margin: 0 0 0 auto;
		padding: 0;
		overflow: hidden;
		transition: all 0.2s ease-out;
		background: #EF4B23;
		height: 0;

		@include media(">=desktop") {
			flex: 0 1 50%;
			height: auto;
			flex-direction: row;
			background: none;
			order: 2;
			align-items: center;
		}

		@include media(">=fullhd") {
			flex: 0 1 40%;
			margin-top: 0;
		}
	}

	.search-menu {
		display: flex;
		flex: 0 0 100%;
		flex-direction: column;
		margin: 0 auto;
		padding: 0;
		overflow: hidden;
		transition: all 0.2s ease-out;
		background:#EF4B23;
		height: 0;
		order: 10; //last
        width: 100%;
        
        &::before{
            content: " ";
            position: absolute;
            top: -1.5rem;
            left: 37.5rem;
            display: inline-block;
            width: 0;
            height: 0;
            border-color: transparent transparent #EF4B23;
            border-style: solid;
            border-width: 0 1.5rem 1.5rem;
        }

		@include media(">=desktop") {
			position: absolute;
			top: 100%;
			z-index: 9999;
			right: 2rem;
			width: 46rem;
		}

		.search-form {
			padding: 0;
        }

		.form {
			width: 100%;
            margin: auto;
            border-bottom: 1px solid #fff;

			@include media(">=desktop"){
				width: 40rem;
			}
		}

		.search-field-wrapper {
			position: relative;
			padding: 1rem;

			& .search-submit {
				position: absolute;
				right: 1rem;
				top: 0;
				bottom: 0;
				margin: auto;
				border: none;
				background: none;
			}
		}

		.input-container{
			position: relative;
    		width: 100%;

			input {
				font-weight: 700;
				padding: 1rem;
				border: none;
				background: transparent;
				color: #fff;
				font-size: 2rem;

				&::placeholder {
					color: #fff;
				}

				&:focus {
					outline: none;
				}
			}

			&::before {
				content: '';
				position: absolute;
				width: 2.2rem;
				height: 2.2rem;
                right: 0;
                margin-right: 2rem;
				margin-top: 10px;
			
                background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABHNCSVQICAgIfAhkiAAAAaBJREFUSEvFlU0yA1EUhb8zMCYrYGRClVgBWQHZAStgB9gBKyArQFmAZAWosgBWIJkyuOqkXqf6vw2ScifdVd3vfvf3PLFi04r90wqIiEPgAPCzn4J5BcbARJKfrVYLiIgN4BY47jj/AJxKmjb9VwFEhCO9B7aAGXAH2JEjt/m7wSfAOvABDCVl3wusAiBF/pKcT+xEkh1ULCIcgOEuof/Zr8ukDHCkR6m+rnunRYT7YMiDpGH5wAKQGvqcytJvirzsIGXi8rhcg3Lj84BL4AK4kXTeGXruh4i4Bs6AK0n2s7A8IEu1EkUXLJe9R7dQ2jzAo+Y0e21j19Bwj/UXMJXUa8pgGYCZJMP+p0Qrb7Kb4zF1qbw0tQvWMKZeTpemeUx9MCKyRRtLGnRNTzrzDuwAI0mWj06p8NJsJsW0kLVJxROwC3wD25I+WwEpIouZMzHE5crE7i0d3suJncvyA6wlMXSJCsraJtd2bF1qs1Ha/kfAYGdfgPzlwnHz6y4c92l+4SQV9nsFsrQrswRZDMnSAKVMLBlzTVoqoK5ZKwf8Aibuvxl2+2ciAAAAAElFTkSuQmCC');

                @include media(">=desktop"){
                    margin-right: 0;
                }
			}
		}
	}

	.nav-item {
		display: flex;
		margin: 1rem auto;
		padding: 2.5rem 0;
		text-align: center;
		font-size: 2.5rem;
		list-style-type: none;
		text-transform: uppercase;
		font-family: 'Gotham', sans-serif;

		@include media(">=desktop") {
			font-size: 1.6rem;
            margin: 0 auto;
            font-weight: 600;
		}

		&__aux {
			position: relative;
		}
        
       

		& > a {
			padding: 2rem .5rem 1rem .5rem;
			text-decoration: none;
			color: #fff;
			position: relative;
			

			&:hover,
			&.active {
				color: #ff4713;
			}

			&.active {
				font-weight: bold;
			}
        }
        
        &--news {
            & > a {
				color: #000;
			}
        }
        
		&:hover .dropdown {
			display: flex;
		}

		&.active {
			a {
				border-bottom: 2px solid #ff4713;
			}
		}

		.dropdown {
			display: none;
			position: absolute;
			top: 96%;
			right: 0;
			left: 0;
			z-index: 9999;
			padding: 0 4rem;
			justify-content: center;
			background: $darker-blue;

			h3, h3>.group-link {
				color: $light-blue;
				font-size: 2rem;
				margin-top: 0;
			}

			.group {
				flex: 0 1 50%;
				display: flex;
				flex-direction: column;
				padding: 2rem;
				text-align: left;

				p {
					margin: 0 0 1rem;
				}

				&-link {
					color: $white;
					text-decoration: none;

					&:hover {
						text-decoration: underline;
					}
				}
			}
		}

		&--secondary {
			font-size: 1.3rem;
		}

		&--secondary > a {
			position: relative;
		}

		.phone {
			padding-left: 2rem;
			color: $orange;
			margin: 2rem 0 2rem -3.2rem;
			display: none;

			&:before {
				display: inline-block;
				content: " ";
				width: 2.2rem;
				height: 2.4rem;
				margin-right: 1rem;
				background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAWCAYAAAAfD8YZAAAABHNCSVQICAgIfAhkiAAAAO9JREFUOE/tlDFuAjEQRefb9KxvQMENaCPBEZIe0OYGoGy4BBvBDbICRE9HCTfITbxIlNg/shDWJorQbhkJN7Y082bG4/GH/RilIBYikki9VRKcmrd1ATsfldLSHTMtyhtr8+EzPS9a6553l52Zbb+ibZEmcN4m2Qoo8zG14qCa1DnXFdU60/snJbJXGucfdo9DhEke61V89QLQj3A4NIFDtQ+4QcceDfufE9bgiaPr3Y9h83EBYTvJ1i+/g98dEjsfDgD1GSDSv5r3zaEaIMJ/yVBwDJnDbrJVWgVtVYaCAIqXJYB2nbuTPImSSRDAbxk12xc4D06vAAAAAElFTkSuQmCC');
				background-position: center;
				background-size: contain;
				background-repeat: no-repeat;
				vertical-align: middle;
			}

			@include media(">=desktop") {
				color: $orange;
				padding-left: 1rem;
				margin: 0 0 0 -1rem;
				display: block;

				&:hover {
					color: $light-blue;
				}

				&:before {
					background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAWCAYAAAAfD8YZAAAABHNCSVQICAgIfAhkiAAAAO9JREFUOE/tlDFuAjEQRefb9KxvQMENaCPBEZIe0OYGoGy4BBvBDbICRE9HCTfITbxIlNg/shDWJorQbhkJN7Y082bG4/GH/RilIBYikki9VRKcmrd1ATsfldLSHTMtyhtr8+EzPS9a6553l52Zbb+ibZEmcN4m2Qoo8zG14qCa1DnXFdU60/snJbJXGucfdo9DhEke61V89QLQj3A4NIFDtQ+4QcceDfufE9bgiaPr3Y9h83EBYTvJ1i+/g98dEjsfDgD1GSDSv5r3zaEaIMJ/yVBwDJnDbrJVWgVtVYaCAIqXJYB2nbuTPImSSRDAbxk12xc4D06vAAAAAElFTkSuQmCC');
				}
			}
		}
	}

	.search-icon {
		padding: 0 28px 28px;
		position: relative;
		cursor: pointer;
		flex-direction: row-reverse;
		display: none;

		@include media(">=desktop") {
			flex: 0 0 1%;
			order: 3;
			margin: 0 auto;
			display: flex;
		}

		.search.icon {
			position: absolute;
			margin-left: 10px;
			margin-top: 7px;
			width: 30px;
			height: 30px;
			background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABHNCSVQICAgIfAhkiAAAAaBJREFUSEvFlU0yA1EUhb8zMCYrYGRClVgBWQHZAStgB9gBKyArQFmAZAWosgBWIJkyuOqkXqf6vw2ScifdVd3vfvf3PLFi04r90wqIiEPgAPCzn4J5BcbARJKfrVYLiIgN4BY47jj/AJxKmjb9VwFEhCO9B7aAGXAH2JEjt/m7wSfAOvABDCVl3wusAiBF/pKcT+xEkh1ULCIcgOEuof/Zr8ukDHCkR6m+rnunRYT7YMiDpGH5wAKQGvqcytJvirzsIGXi8rhcg3Lj84BL4AK4kXTeGXruh4i4Bs6AK0n2s7A8IEu1EkUXLJe9R7dQ2jzAo+Y0e21j19Bwj/UXMJXUa8pgGYCZJMP+p0Qrb7Kb4zF1qbw0tQvWMKZeTpemeUx9MCKyRRtLGnRNTzrzDuwAI0mWj06p8NJsJsW0kLVJxROwC3wD25I+WwEpIouZMzHE5crE7i0d3suJncvyA6wlMXSJCsraJtd2bF1qs1Ha/kfAYGdfgPzlwnHz6y4c92l+4SQV9nsFsrQrswRZDMnSAKVMLBlzTVoqoK5ZKwf8Aibuvxl2+2ciAAAAAElFTkSuQmCC');

			background-repeat: no-repeat;
		}

		&--alt {
			.search.icon {
				background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABHNCSVQICAgIfAhkiAAAAYRJREFUSEvFld0tBUEYhp9z4RoVcOWGBBWgAnRABXSADqgAFSAKQAVIFEAFuOVGHpk5mf2Z2ZXsxpec7Elm9nt/vp+dMHJMRs5PF8AmsAH4XAtknoB74CE8ixxzAHPAObDTofAa2Ac+cvfaAGR6BSwCn8AFYCKZG54LvAfMAq/AbnJewaoDyPwxJNcCk5igLSQguBZ6Z71NSR1AptvBX33vE9ZDEN9VSVaBCe+CLdqQY17PoRLt066teuFTBcfAEXAGHPahntw5BQ6AE8A800gBotQGix5gUb11q1ibAthqypwvtV0GzOZ4D+/5fquCIQBsa8H+x6LRixwLpVUOzV/a1OHUmmKb6lscNDvKy33iBVgGLsPkZwfNA1k4NAthYFxkpVVxC6wAX8AS8Faa5HjmFKtEEO2Ky+45XFhNlp2EvoGZQEzVlc1aWtcmdi+VQluc/htAYNVXQPp8cCx+2wfHOvmL1vq/AdIF0KfI8Y52RZBpkwwJkCqxDr87aWiAhuLRAX4ARwFcGelAZ+cAAAAASUVORK5CYII=');
			}

		}
	}

	.search-icon-mobile {
		padding: 24px 28px 60px;
		position: relative;
		cursor: pointer;
		display: flex;
		flex-direction: row-reverse;
		border-right: 2px solid #fff;

		@include media(">=desktop") {
			flex: 0 0 1%;
			order: 3;
			padding-right: 0;
			margin: 0 auto;
			display: none;
		}

		.search.icon-mobile {
			position: absolute;
			margin-top: 2px;
			margin-left: 10px;
			width: 30px;
			height: 30px;
			background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABHNCSVQICAgIfAhkiAAAAaBJREFUSEvFlU0yA1EUhb8zMCYrYGRClVgBWQHZAStgB9gBKyArQFmAZAWosgBWIJkyuOqkXqf6vw2ScifdVd3vfvf3PLFi04r90wqIiEPgAPCzn4J5BcbARJKfrVYLiIgN4BY47jj/AJxKmjb9VwFEhCO9B7aAGXAH2JEjt/m7wSfAOvABDCVl3wusAiBF/pKcT+xEkh1ULCIcgOEuof/Zr8ukDHCkR6m+rnunRYT7YMiDpGH5wAKQGvqcytJvirzsIGXi8rhcg3Lj84BL4AK4kXTeGXruh4i4Bs6AK0n2s7A8IEu1EkUXLJe9R7dQ2jzAo+Y0e21j19Bwj/UXMJXUa8pgGYCZJMP+p0Qrb7Kb4zF1qbw0tQvWMKZeTpemeUx9MCKyRRtLGnRNTzrzDuwAI0mWj06p8NJsJsW0kLVJxROwC3wD25I+WwEpIouZMzHE5crE7i0d3suJncvyA6wlMXSJCsraJtd2bF1qs1Ha/kfAYGdfgPzlwnHz6y4c92l+4SQV9nsFsrQrswRZDMnSAKVMLBlzTVoqoK5ZKwf8Aibuvxl2+2ciAAAAAElFTkSuQmCC');

            background-repeat: no-repeat;
            
            &--alt {
                background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABHNCSVQICAgIfAhkiAAAAYRJREFUSEvFld0tBUEYhp9z4RoVcOWGBBWgAnRABXSADqgAFSAKQAVIFEAFuOVGHpk5mf2Z2ZXsxpec7Elm9nt/vp+dMHJMRs5PF8AmsAH4XAtknoB74CE8ixxzAHPAObDTofAa2Ac+cvfaAGR6BSwCn8AFYCKZG54LvAfMAq/AbnJewaoDyPwxJNcCk5igLSQguBZ6Z71NSR1AptvBX33vE9ZDEN9VSVaBCe+CLdqQY17PoRLt066teuFTBcfAEXAGHPahntw5BQ6AE8A800gBotQGix5gUb11q1ibAthqypwvtV0GzOZ4D+/5fquCIQBsa8H+x6LRixwLpVUOzV/a1OHUmmKb6lscNDvKy33iBVgGLsPkZwfNA1k4NAthYFxkpVVxC6wAX8AS8Faa5HjmFKtEEO2Ky+45XFhNlp2EvoGZQEzVlc1aWtcmdi+VQluc/htAYNVXQPp8cCx+2wfHOvmL1vq/AdIF0KfI8Y52RZBpkwwJkCqxDr87aWiAhuLRAX4ARwFcGelAZ+cAAAAASUVORK5CYII=');
            }
		}
	}

	.menu-icon {
		padding: 28px;
		position: relative;
		cursor: pointer;
		display: flex;
		flex-direction: row-reverse;
		border-left: 1px solid transparent;

		@include media(">=desktop") {
			display: none;
		}

		.nav-icon {
			background: #fff;
			display: block;
			height: 3px;
			width: 24px;
			position: relative;
			transition: 0.2s ease-out;
			

			&:before {
				background: #fff;
				content: "";
				display: block;
				height: 100%;
				width: 100%;
				position: absolute;
				top: 6px;
				transition: all 0.2s ease-out;
			}

			&:after {
				background: #fff;
				content: "";
				display: block;
				height: 100%;
				width: 100%;
				position: absolute;
				top: -6px;
				transition: all 0.2s ease-out;
            }


            
            &--alt {
                background: #000;


                &:before {
                    background: #000;
                    content: "";
                }
    
                &:after {
                    background: #000;
                    content: "";
                }
            }
		}
	}

	&--article {
		position: relative;
		&--alt {
			position: relative;

			@include media(">=desktop") {
				position: absolute;
			}

			.search-icon-mobile {
				.search.icon-mobile {
					background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABHNCSVQICAgIfAhkiAAAAYRJREFUSEvFld0tBUEYhp9z4RoVcOWGBBWgAnRABXSADqgAFSAKQAVIFEAFuOVGHpk5mf2Z2ZXsxpec7Elm9nt/vp+dMHJMRs5PF8AmsAH4XAtknoB74CE8ixxzAHPAObDTofAa2Ac+cvfaAGR6BSwCn8AFYCKZG54LvAfMAq/AbnJewaoDyPwxJNcCk5igLSQguBZ6Z71NSR1AptvBX33vE9ZDEN9VSVaBCe+CLdqQY17PoRLt066teuFTBcfAEXAGHPahntw5BQ6AE8A800gBotQGix5gUb11q1ibAthqypwvtV0GzOZ4D+/5fquCIQBsa8H+x6LRixwLpVUOzV/a1OHUmmKb6lscNDvKy33iBVgGLsPkZwfNA1k4NAthYFxkpVVxC6wAX8AS8Faa5HjmFKtEEO2Ky+45XFhNlp2EvoGZQEzVlc1aWtcmdi+VQluc/htAYNVXQPp8cCx+2wfHOvmL1vq/AdIF0KfI8Y52RZBpkwwJkCqxDr87aWiAhuLRAX4ARwFcGelAZ+cAAAAASUVORK5CYII=');
				}
			}

			.menu-icon {

				.nav-icon {
					background-color: #000;
	
					&:before {
						background-color: #000;
					}
		
					&:after {
						background-color: #000;
					}
	
					@include media(">=desktop") {
						background: inherit;
	
						&:before {
							background: inherit;
						}
			
						&:after {
							background: inherit;
						}
					}
				}
	
			}
		}
    }

	.menu-btn,
	.search-btn {
		display: none;
		flex: 0 0 0%;
	}

	.menu-btn:checked ~ .menu {
		//show/hide transition
		flex: 1 1 100%;
		height: auto;
		padding: 2rem 0;
		z-index: 9999;
        
        .nav-item {

            a {
                color: #fff;
            }
        }
	}

	.menu-btn:checked ~ .menu-icon {
		background: #EF4B23;
		border-left: 1px solid #fff;
	}

	.menu-btn:checked ~ .menu-icon .nav-icon {
		background: transparent;
	}

	.menu-btn:checked ~ .menu-icon .nav-icon:before {
		transform: rotate(-45deg);
		top: 0;
	}

	.menu-btn:checked ~ .menu-icon .nav-icon::after {
		transform: rotate(45deg);
		top: 0;
	}

	.search-btn:checked ~ .search-icon {
		//transform: rotate(-90deg);
	}

	.search-btn:checked ~ .search-menu {
		//show/hide transition
        flex: 1 1 100%;
        height: auto;
        padding: 2rem 0;
        overflow: visible;

        @include media(">=tablet"){
            padding: 2rem;
        }
	}
}

.nav-background {
	background-color: #fff;

	.nav-item {
		a {
			color: #fff;
			@include media(">=desktop") {
				color: #000;
			}
		}
	}

	.menu-icon{
		.nav-icon {
			background: #000;
	
			&:before {
				background: #000;
				content: "";
			}
	
			&:after {
				background: #000;
				content: "";
			}
		}
	}

	.search-icon{
		.search.icon {
			background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABHNCSVQICAgIfAhkiAAAAYRJREFUSEvFld0tBUEYhp9z4RoVcOWGBBWgAnRABXSADqgAFSAKQAVIFEAFuOVGHpk5mf2Z2ZXsxpec7Elm9nt/vp+dMHJMRs5PF8AmsAH4XAtknoB74CE8ixxzAHPAObDTofAa2Ac+cvfaAGR6BSwCn8AFYCKZG54LvAfMAq/AbnJewaoDyPwxJNcCk5igLSQguBZ6Z71NSR1AptvBX33vE9ZDEN9VSVaBCe+CLdqQY17PoRLt066teuFTBcfAEXAGHPahntw5BQ6AE8A800gBotQGix5gUb11q1ibAthqypwvtV0GzOZ4D+/5fquCIQBsa8H+x6LRixwLpVUOzV/a1OHUmmKb6lscNDvKy33iBVgGLsPkZwfNA1k4NAthYFxkpVVxC6wAX8AS8Faa5HjmFKtEEO2Ky+45XFhNlp2EvoGZQEzVlc1aWtcmdi+VQluc/htAYNVXQPp8cCx+2wfHOvmL1vq/AdIF0KfI8Y52RZBpkwwJkCqxDr87aWiAhuLRAX4ARwFcGelAZ+cAAAAASUVORK5CYII=');
		}
	}

	.search-icon-mobile {
		
		border-right: 2px solid #000;

		.search.icon-mobile {
			background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABHNCSVQICAgIfAhkiAAAAYRJREFUSEvFld0tBUEYhp9z4RoVcOWGBBWgAnRABXSADqgAFSAKQAVIFEAFuOVGHpk5mf2Z2ZXsxpec7Elm9nt/vp+dMHJMRs5PF8AmsAH4XAtknoB74CE8ixxzAHPAObDTofAa2Ac+cvfaAGR6BSwCn8AFYCKZG54LvAfMAq/AbnJewaoDyPwxJNcCk5igLSQguBZ6Z71NSR1AptvBX33vE9ZDEN9VSVaBCe+CLdqQY17PoRLt066teuFTBcfAEXAGHPahntw5BQ6AE8A800gBotQGix5gUb11q1ibAthqypwvtV0GzOZ4D+/5fquCIQBsa8H+x6LRixwLpVUOzV/a1OHUmmKb6lscNDvKy33iBVgGLsPkZwfNA1k4NAthYFxkpVVxC6wAX8AS8Faa5HjmFKtEEO2Ky+45XFhNlp2EvoGZQEzVlc1aWtcmdi+VQluc/htAYNVXQPp8cCx+2wfHOvmL1vq/AdIF0KfI8Y52RZBpkwwJkCqxDr87aWiAhuLRAX4ARwFcGelAZ+cAAAAASUVORK5CYII=');
		}
	}
	
}
