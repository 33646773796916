.quote-container {
    background-color: #F7901E;
    color: #fff; 
    text-align: center;

    .content-container {
        margin: 1rem;

        .quote-text {
            

            p {
                font-size: 2.5rem;
                font-weight: 100;
                font-style: italic;
                font-family: 'Lexia';
            }
        }

        .quote-mark-container {
           
            
            p {
                font-family: 'Noto Sans';
                font-size: 7rem; 
                margin: 0;
            }
        }
    }

    
    
}

.heading-section {
    width: 100%;

    .heading-container {
        width: 89%;
        margin: 0 auto;
        text-align: left;

        h4 {
            margin-bottom: 0;
            font-weight: 600;
        }

        &--contact {
            width: 85%;

            @include media(">=tablet") {
                width: 91%;
            }

            @include media(">=desktop") {
                width: 75%;
            }
        }

        &--search {
            border-bottom: 1px dotted #707070;
            padding-bottom: 1rem;
            margin-bottom: 3rem;
            width: 100%;
        }
    }
}

.cite-container {
    height: 10rem;
    width: 100%;
    text-align: center;
    padding-top: 2rem;

    p {
        font-size: 1.2rem;
        color: #EF4B23;
        font-weight: bold;
        font-family: 'Gotham', sans-serif;
    }
}

.about-single-section {
    width: 100%;
    margin: 5rem 0;

    .about-single-container {
        max-width: 56rem;
        margin: 0 auto;
        padding: 3rem;

        p {
            font-family: Lexia;
            line-height: 1.5;
        }

        h4 {
            margin-top: 1rem;
        }

        @include media(">=desktop") {
            padding: 1rem;;
        }
    }
}