@-webkit-keyframes bounce {
	0%, 20%, 50%, 80%, 100% {-webkit-transform: translateY(0);}	
	40% {-webkit-transform: translateY(-10px);}
	60% {-webkit-transform: translateY(-5px);}
}
 
@-moz-keyframes bounce {
	0%, 20%, 50%, 80%, 100% {-moz-transform: translateY(0);}
	40% {-moz-transform: translateY(-10px);}
	60% {-moz-transform: translateY(-5px);}
}
 
@-o-keyframes bounce {
	0%, 20%, 50%, 80%, 100% {-o-transform: translateY(0);}
	40% {-o-transform: translateY(-10px);}
	60% {-o-transform: translateY(-5px);}
}
@keyframes bounce {
	0%, 20%, 50%, 80%, 100% {transform: translateY(0);}
	40% {transform: translateY(-10px);}
	60% {transform: translateY(-5px);}
}

@keyframes toGreyscale {
	0%    { -webkit-filter: grayscale(0%); filter: grayscale(0%); }
    25%   { -webkit-filter: grayscale(25%); filter: grayscale(25%); }
    50%   { -webkit-filter: grayscale(50%); filter: grayscale(50%); }
    75%   { -webkit-filter: grayscale(75%); filter: grayscale(75%); }
    100%  { -webkit-filter: grayscale(100%); filter: grayscale(100%); }
}