﻿html {
	font-size: 62.5%; /* reduces default browser size of 16px to 10px */
	font-family: Lexia;
	box-sizing: border-box;
}

body {
	font-family: 'Gotham', sans-serif;
	overflow-x: hidden;
	overflow-y: hidden;
}

h1 {
	font-size: 6.5rem;
	font-weight: bold;
	margin: .2rem 0
}

h2 {
	font-family: 'Gotham', sans-serif;
	font-size: 4.5rem;
	font-weight: bold;
	margin: 0
}

h3 {
	font-family: 'Gotham', sans-serif;
	font-size: 3.5rem;
	font-weight: bold;
}

h4 {
	font-family: 'Gotham', sans-serif;
	font-size: 2.5rem;
	font-weight: bold;
	line-height: 2.8rem;
}

h5 {
	font-family: 'Gotham', sans-serif;
	font-size: 1.6rem;
	font-weight: bold;
	line-height: 1.9rem;
	text-transform: uppercase;
}

p {
	font-size: 1.6rem;
	font-family: Lexia;
}

option {
	font-family: 'Gotham', sans-serif;
}

li {
	font-family: 'Lexia';
}

@font-face {
    font-family: "Altero Outline";
    src: url(/../Assets/fonts/altero-outline/Altero-Outline.otf) format("truetype");
}