.faq-heading {
    width: 94%;
    margin: 0 auto;

    h5 {
        margin-bottom: 1rem;
    }

    h2 {
        margin-top: 0;
    }

    p {
        margin: 0;
    }
}

.legal-heading {
    width: 94%;
    margin: 0 auto;

    h5 {
        margin-bottom: 1rem;
    }

    p {
        margin: 0;
    }
}
.accordion {

    &--work{
        margin-bottom: 2rem;
    }

    ul {
        color: grey;
        margin-left: 3rem;
    }

	input {
		position: absolute;
		opacity: 0;
		z-index: -1;
	}

	/* Accordion styles */
	.sections {
		width: 90%;
		margin: auto;
        overflow: hidden;
        
        &--news {
            margin: 0 auto;

            @include media(">=desktop") {
                margin: 0 0 0 auto;
            }
        }
	}
	
	.section {
		margin: auto;
		width: 100%;
		color: black;
		overflow: hidden;
        border-bottom: 1px dotted #707070;

        &--news {
            border-top: 3px solid #EF4B23;
            border-bottom: none;
        }

        &--about {
            border-bottom: 1px dotted #fff;
            margin: 0 0 2rem 0;
        }
        
        p {
           font-family: Lexia;
           font-weight: 500;
        }

		&-label {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 1.6em 1.6em 1.6em 1rem;
			background: #fff;
			font-weight: 600;
			font-size: 1.5rem;
            cursor: pointer;

			/* Icon */
			&:hover {
				background: darken(#fff, 10%);

				> .glossary-icon,
				.glossary-icon:before {
					
						
					
				}
            }

            &--work {
                background: transparent;
                color: #fff;

                &:hover {
                    background: none;
                    color: #fff;

                    @include media(">=desktop") {
                        color: #EF4B23;
                    }
                }
            }
            
            &--about {
                background-color: #005E61;
                color: #fff;
                font-size: 22px;
                padding: .6em;

                &:hover {
                    background: none;
    
                    > .glossary-icon,
                    .glossary-icon:before {
                        
                        background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAALCAYAAAByF90EAAAABHNCSVQICAgIfAhkiAAAAOZJREFUKFON0jFqAkEUxvH/dwSrHCGdeATBwiqNRWothWQbC5tAAjYpgiCKjZ2nCAgpZSE38A52NoLwwsisvKyju9PNzJsf35sZAZjZEsglrcO8zjCzRyCce5a0l5l9AuPgAf06mJm1gG/gAfgFOgH6AN5jikosIj9AI57ZAl3F1spYJmlRbjGBbICepMMZSmBh6dVjN5AnScdQfIEi9gZMXJIzVoVcQRF7AeYOmwIDdyehnUuSou5fItdmGSu2kkgykcNGwJdLlgPt4k7KD5FM5LAZkAE7oHkLuZvIYUNgJel078f/AcLRYB+TgHF7AAAAAElFTkSuQmCC');
                        background-repeat: no-repeat;
                        
                    }
                }
            }

			.glossary-icon {
                background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAALCAYAAAByF90EAAAAwElEQVQokWMAAWVl5Ze2trZbGUgAISEh0QICAr9iYmL0wbo0NTXvMzAw/AdhYg0LCAjIYWJiAuvh5ub+U1VVJcmgp6d3E2YQMYaBDGFkZISr5+Hh+V1VVSUOlkQ3zNzc/CgxhggKCv6MjIyUQ1FEyDBshiQnJwthdbaBgcFVbIaRZAgMQDXDNamrqz8m2RBchpFlCAyYmJicRTaEh4fnD8mGwICqquozkCGsrKz/yDYEBiwtLQ+lpaVx4VXEwMAAAAjBZaY/FALiAAAAAElFTkSuQmCC');
                background-repeat: no-repeat;
				display: block;
				height: 14px;
				width: 23px;
				position: relative;
                transition: 0.2s ease-out;

                &--about{
                    background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAALCAYAAAByF90EAAAABHNCSVQICAgIfAhkiAAAAOZJREFUKFON0jFqAkEUxvH/dwSrHCGdeATBwiqNRWothWQbC5tAAjYpgiCKjZ2nCAgpZSE38A52NoLwwsisvKyju9PNzJsf35sZAZjZEsglrcO8zjCzRyCce5a0l5l9AuPgAf06mJm1gG/gAfgFOgH6AN5jikosIj9AI57ZAl3F1spYJmlRbjGBbICepMMZSmBh6dVjN5AnScdQfIEi9gZMXJIzVoVcQRF7AeYOmwIDdyehnUuSou5fItdmGSu2kkgykcNGwJdLlgPt4k7KD5FM5LAZkAE7oHkLuZvIYUNgJel078f/AcLRYB+TgHF7AAAAAElFTkSuQmCC');
                    background-repeat: no-repeat;
                }
                
                &--work {
                    background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAALCAYAAAByF90EAAAABHNCSVQICAgIfAhkiAAAAOZJREFUKFON0jFqAkEUxvH/dwSrHCGdeATBwiqNRWothWQbC5tAAjYpgiCKjZ2nCAgpZSE38A52NoLwwsisvKyju9PNzJsf35sZAZjZEsglrcO8zjCzRyCce5a0l5l9AuPgAf06mJm1gG/gAfgFOgH6AN5jikosIj9AI57ZAl3F1spYJmlRbjGBbICepMMZSmBh6dVjN5AnScdQfIEi9gZMXJIzVoVcQRF7AeYOmwIDdyehnUuSou5fItdmGSu2kkgykcNGwJdLlgPt4k7KD5FM5LAZkAE7oHkLuZvIYUNgJel078f/AcLRYB+TgHF7AAAAAElFTkSuQmCC');
                    background-repeat: no-repeat;
                }
			}
		}
		&-content {
			margin: 0;
			color: #fff;
			background: #005E61;
			transition: all;
			height: 0;
			position: absolute;
			visibility: hidden;
			opacity: 0;

			p {
				color: #fff;
            }
            &--about {
                background: #313E48;
            }

            &--projects {
				background: rgba(0,0,0,.88);

				.projects-links {
					color: #fff;
					padding: 1rem;
                    border-bottom: 1px dotted rgb(82,87,92);
                    .active {
                        color: #ef4b23;
                        text-transform: uppercase;
                        font-weight: bold;
                    }

					&:last-of-type {border-bottom: none;}

					h3 {
						font-size: 1.5rem;
						font-weight: 700;
					}

					label {
						color: #fff;
						text-decoration: none;
						font-size: 1.5rem;
						font-weight: normal;
                    }
                    
                    a {
                        color: #fff;
                    }
 
				}
			}
		}

		&-close {
			display: flex;
			justify-content: flex-end;
			padding: 1em;
			font-size: inherit;
			background: #458ac9;
			cursor: pointer;
			
			&:hover {
				background: grey;
			}
		}
	}

	// :checked
	input:checked {
		+ .section-label {
            background: #313e48;
			.glossary-icon {
				transform: rotate(180deg);

				&::before {
					display: none;
				}
            }
            
            &--news {
                color: #fff;
            }
		}
		~ .section-content {
			min-height: 8rem;
            height: 100%;
            padding: 2rem;
			position: relative;
			visibility: visible;
            opacity: 1;
            
            h3 {
                margin-bottom: 0;
            }

            h5 {
                margin: 1rem 0;
            }

            &--projects {
                height: auto;
				width: 90%;
				margin-top: 0;
				padding: 0;
                position: absolute;
                z-index: 9999;
            }
		}
	}

	.heading-container {
		width: 90%;
		margin: auto;

		h1 {
			color: grey;
		}
    }
    
}

.about-service-section {
    width: 100%;
    min-height: 50rem;
    background-color: #005E61;
    padding-top: 2rem;
    margin-bottom: 3rem;;

    h3 {
        color: #fff;
        text-align: center;
    }

    .accordion-container {
        width: 90%;
        margin: auto;
    }
}