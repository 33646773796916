.two-column-section {
    min-height: 45rem;
    margin-bottom: 5rem;

    .container {
        margin: inherit;

        @include media(">=desktop") {
            margin: 4rem auto;
        }
    }

    .text-container {
        max-width: 66rem;
        margin-right: 0;
        padding: 3rem;

        p {
            font-family: Lexia;
            line-height: 1.5;

            img {
                width: 100%;
                height: auto;
            }
        }

        @include media(">=desktop") {
            margin-right: 4rem;
            padding: inherit;

            h4 {
                margin-top: 0;
            }
        }
    }

    @include media(">=desktop") {
        margin: inherit;
    }
}

.about-section {
    width: 100%;
    margin: 4rem 0;

    .about-container {
        width: 90%;
        margin: auto;

        &--alt {
            display: flex;
            flex-direction: column-reverse;

            @include media(">=desktop") {
                flex-direction: row;
            }
        }

        p {
            font-family: Lexia;
            line-height: 1.5;
            
            strong {
                font-family: 'Gotham', sans-serif;
            }
        }

        a {
            padding: 1rem;
            font-size: 1.6rem;
            font-weight: bold;
            color: #EF4B23;
            cursor: pointer;
            margin-left: 0;
            border: none;
            text-decoration: none;

            @include media(">=desktop") {
                margin-left: 6rem;
            }

            &:hover {
                border-bottom: 2px solid #EF4B23;
                transition: ease-in;
            }
        }
    }

    .img-container {
        width: 100%;

        img {
            width: 100%;
        }
    }

    .text-container {
        padding: inherit;

        strong {
            font-family: 'Gotham', sans-serif;
        }

        @include media(">=desktop") {
            padding: 6rem;
        }

        h4 {
            margin-top: 1rem;
        }
    }
}